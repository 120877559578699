// libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Moment from 'react-moment';
import debounce from 'lodash/debounce';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import { DateRangePicker } from 'materialui-daterange-picker';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// UI components
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import FormatQuoteOutlinedIcon from '@material-ui/icons/FormatQuoteOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SendOutlined from '@material-ui/icons/SendOutlined';
import Divider from '@material-ui/core/Divider';
import TableHead from '@material-ui/core/TableHead';
import LoaderModal from '../components/LoaderModal.js';
import Messenger from '../components/Messenger';
import NewQRModal from '../components/NewQRModal';
import Logo from '../components/LogoMark.js';
import Map from '../components/Map.js';
import YardenTable from '../components/YardenTable.js';
import OrderDetails from '../components/OrderDetails';
import Images from '../components/Images';
import NewOrder from '../components/NewOrder';
import NewBid from '../components/NewBid';
import QRCodeDetails from '../components/QRCodeDetails';
import TemplateDetails from '../components/TemplateDetails.js';
import BidDetails from '../components/BidDetails.js';
import ImageModal from '../components/ImageModal';
import ApplicationDetails from '../components/ApplicationDetails';
import TaskModal from '../components/TaskModal';
import TaskDetails from '../components/TaskDetails';
import Tabs from '../components/UI/Tabs';
import Plants from '../components/Plants';
import Pagination from '../components/UI/Pagination';
import ToggleSwitch from '../components/ToggleSwitch';

// helpers
import { truncate } from '../formatting/truncate.js';
import { validateCurrency } from '../formatting/validateCurrency.js';
import { getBadgeStyles } from '../helpers/getBadgeStyles.js';
import delimit from '../formatting/delimit.js';
import { paginate } from '../formatting/paginate';
import { formatNumber } from '../formatting/phone';
import getQuotePricing from '../helpers/getQuotePricing.js';
import logInfo from '../helpers/logInfo';

// context
import { LineItemsContextProvider } from '../context/LineItemsContext';

// actions
import {
    getIndicatorStyles,
    mapDispatchToProps,
} from '../actions/Dispatcher.js';

// vars
import { ADMIN, PARTNER, GARDENER } from '../vars/types.js';
import { MISC_ORDER_PLATFORM_FEE } from '../vars/constants';

// styling
import '../css/dashboard.css';

let Dashboard = (props) => {
    const [state, setState] = useState({
        allImages: [],
        orderSearch: '',
        userSearch: '',
        bidSearch: '',
        itemSearch: '',
        ruleSearch: '',
        taskSearch: '',
        applicationSearch: '',
        qrCodeSearch: '',
        templateSearch: '',
        pageLimit: 5,
        currentOrderPage: 1,
        currentBidPage: 1,
        currentImagePage: 1,
        selectedVendor: 'all',
        lastReload: new Date(),
        dateLimit: props.user.data.type === 'admin' ? 'month' : 'today',
        totalBids: 0,
        materials: [{ name: '', url: '', price: '', qty: '' }],
        beds: [{ width: 0, length: 0, height: 0, qty: 0 }],
        hours: '',
        rate: '',
        deliveryFee: '',
        rentalFee: '',
        disposalFee: '',
        materialsRequired: false,
        deliveryRequired: false,
        rentalsRequired: false,
        disposalRequired: false,
        estimatedStartDate: new Date(),
        materialsTotal: 0,
        laborTotal: 0,
        deliveryTotal: 0,
        rentalTotal: 0,
        disposalTotal: 0,
        totalMessages: 0,
        selectedTemplate: 'none',
        bidType: 'misc',
        itemQty: '',
        itemName: '',
        itemUrl: '',
        itemPrice: '',
        itemNames: [],
        unitType: 'bid',
        unitQty: '',
        ruleBuilderTitle: 'Create',
        itemBuilderTitle: 'Create',
        renderDateRangePicker: false,
        loadingModalIsOpen: false,
        mapData: [],
        totalOrders: 0,
        selectedOrder: null,
        selectedTask: null,
        selectedBid: null,
        selectedApplication: null,
        selectedProduct: null,
        selectedItem: null,
        selectedRule: null,
        viewOrderDetails: false,
        viewTaskDetails: false,
        viewTasks: false,
        deliveryItems: '',
        rentalItems: '',
        disposalItems: '',
        viewOrders: true,
        viewBids: false,
        viewUsers: false,
        viewMaterials: false,
        viewApplications: false,
        viewQRCodes: false,
        viewMessages: false,
        viewTemplates: false,
        viewAutomation: false,
        viewSettings: false,
        newQRModalIsOpen: false,
        taskStatus: 'pending',
        orderStatus: 'pending',
        newItem: false,
        newRule: false,
        showMap: false,
        orderType: 'all',
        showOrderFilters: false,
    });

    useEffect(() => {
        const initPageData = async function () {
            const urlParams = Object.fromEntries(
                new URLSearchParams(window.location.search),
            );

            if (urlParams.sender) return handleContentClick('Messages');

            const range = await getDateRange();
            const query = `status=pending&page=${state.currentOrderPage}&limit=${state.pageLimit}&start=none&end=${range.endDate}`;

            const orders = await props.getOrders(query);
            const bids = await props.getBids(
                `status=${props.bidStatus.type}&page=${state.currentBidPage}&limit=${state.pageLimit}`,
            );

            const totalBids = bids.data.total;
            const totalMessages = await props.getUnread(props.user);

            if (props.user.data.type === ADMIN) await props.getUsers();
            await props.getTemplates();
            await props.getShapes();

            updateState({
                viewOrders: true,
                totalOrders: orders.data.total,
                totalBids: totalBids,
                totalMessages: totalMessages,
            });

            logInfo('Dashboard page loaded', { user: props.user.data });
        };

        initPageData();
    }, []);

    useEffect(() => {
        if (props.orders.data) {
            setMap(props.orders);
        }
    }, [props.orders]);

    const updateState = async (newValue, callback) => {
        setState((prevState) => ({
            ...prevState,
            ...newValue,
        }));

        if (typeof callback === 'function') {
            callback();
        }
    };

    const setValue = (e) => {
        updateState({ [e.target.name]: e.target.value });
    };

    const handleNavOpen = () => {
        setNavOpen(!navOpen);
    };

    const setMap = async (orders) => {
        let mapData = [];
        orders.data.list.forEach((order, index) => {
            const a = Math.round(new Date().getTime() / 1000);
            const b = Math.round(new Date(order.date).getTime() / 1000);
            const isMaintenance =
                order.type === 'assisted plan' || order.type === 'full plan';
            const pastDue = isMaintenance && a - b >= 86400;
            const orderTypeIndicator = getIndicatorStyles(order);
            const markerLabelStyles = {
                padding: '5px 5px',
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow:
                    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
            };

            if (pastDue) {
                markerLabelStyles.backgroundColor = 'var(--red1)';
                markerLabelStyles.color = 'var(--white)';
            }

            mapData.push(
                <MarkerWithLabel
                    key={index}
                    children={
                        <div className="flex flex-center-y">
                            <div
                                className={`order-type-indicator round mr5 ${orderTypeIndicator}`}
                            ></div>
                            {order.customer.address}{' '}
                        </div>
                    }
                    labelAnchor={{
                        lat: parseFloat(order.customer.geolocation.lat),
                        lng: parseFloat(order.customer.geolocation.lon),
                    }}
                    position={{
                        lat: parseFloat(order.customer.geolocation.lat),
                        lng: parseFloat(order.customer.geolocation.lon),
                    }}
                    onClick={() => {
                        handleContentClick('Order Details', order);
                    }}
                    labelStyle={markerLabelStyles}
                />,
            );
        });

        updateState({
            mapData: mapData,
        });
    };

    const handleContentClick = async (title, data) => {
        await changeView(title.toLowerCase(), data);
        setContent(title);
        setIsSettingsActive(false);
        setActiveTitle(title);
        setHeaderContent(null);
    };

    const handleHeaderContentClick = async (title) => {
        await changeView(title.toLowerCase());
        setHeaderContent(title);
        setActiveTitle(title);
        setContent(null);
        setIsSettingsActive(false);
    };

    const changeView = async (view, data) => {
        updateState({ loadingModalIsOpen: true }, async () => {
            const range = await getDateRange();
            if (view === 'users') await props.getUsers();
            if (view === 'user details') await setPlan(data);
            if (view === 'applications') await props.getApplications();

            if (view === 'orders') {
                let query = `status=${props.status.type}&page=${
                    state.currentOrderPage
                }&limit=${state.pageLimit}${
                    props.status.type === 'pending'
                        ? `&start=${range.startDate}&end=${range.endDate}`
                        : ''
                }${
                    state.selectedVendor !== 'all'
                        ? `&vendor=${state.selectedVendor}`
                        : ''
                }${
                    state.orderType !== 'all' ? `&type=${state.orderType}` : ''
                }`;
                if (state.orderSearch) query += `&search=${state.orderSearch}`;
                await props.getOrders(query);
            }

            if (view === 'order details') {
                await props.getChangeOrders(`order=${data._id}`);
                await props.getBeds(`customer=${data.customer._id}`);
                if (
                    data.type === 'full plan' ||
                    data.type === 'assisted plan' ||
                    data.type === 'initial planting'
                ) {
                    await props.getPlantSelection(data.customer._id);
                }
                if (data.type === 'initial planting') {
                    const specialRequest = await props.getSpecialRequest(
                        `order=${data._id}`,
                    );
                    await updateState({ specialRequest: specialRequest.data });
                }
            }

            if (view === 'history') {
                let query = `status=complete&page=${
                    state.currentOrderPage
                }&limit=${state.pageLimit}${
                    props.status.type === 'pending'
                        ? `&start=${range.startDate}&end=${range.endDate}`
                        : ''
                }${
                    state.selectedVendor !== 'all'
                        ? `&vendor=${state.selectedVendor}`
                        : ''
                }${
                    state.orderType !== 'all' ? `&type=${state.orderType}` : ''
                }`;
                if (state.orderSearch) query += `&search=${state.orderSearch}`;
                await props.getOrders(query);
            }

            if (view === 'reminders') {
                let query = `status=${props.taskStatus.type}`;
                if (state.taskSearch) query += `&search=${state.taskSearch}`;
                props.getTasks(query);
            }

            if (view === 'quotes') {
                let query = `status=${props.bidStatus.type}&page=${state.currentBidPage}&limit=${state.pageLimit}`;
                if (state.bidSearch) query += `&search=${state.bidSearch}`;
                await props.getBids(query);
            }

            if (view === 'materials') {
                await props.getRules();
                await props.getItems();
                await props.getProducts();
            }

            if (view === 'automation') {
                await props.getRules();
                await props.getItems();
                await props.getProducts();
            }

            if (view === 'messages') {
                let customers = [];
                if (props.user.data.type !== ADMIN) {
                    const orders = await props.getOrders(
                        `status=pending&start=none&end=${moment().add(
                            1,
                            'year',
                        )}`,
                        true,
                    );
                    orders.data.list.forEach((order) => {
                        const exists = customers.find(
                            (customer) => customer._id === order.customer._id,
                        );
                        if (!exists) {
                            customers.push(order.customer);
                        }
                    });

                    await updateState({ customers: customers });
                }
            }

            if (view === 'quote details') {
                if (data.vendor) {
                    if (
                        props.user.data._id === data.vendor._id &&
                        !data.opened
                    ) {
                        await props.updateBid(data._id, { opened: true });
                        await updateState({ totalBids: state.totalBids - 1 });
                    }
                }
            }

            if (view === 'user details') {
                await props.getReferrals(`referrer=${data._id}`);
            }

            if (view === 'qr codes') {
                await props.getQRCodes();
            }

            if (view === 'qr code details') {
                await props.getScans(`qr_code=${data._id}`);
            }

            updateState(
                {
                    viewOrders:
                        view === 'orders' || view === 'history' ? true : false,
                    viewHistory: view === 'history' ? true : false,
                    viewBids: view === 'quotes' ? true : false,
                    selectedOrder: view === 'order details' ? data : false,
                    selectedBid:
                        view === 'quote details' || view === 'edit quote'
                            ? data
                            : false,
                    selectedTemplate: view === 'edit template' ? data : false,
                    viewOrderDetails: view === 'order details' ? true : false,
                    viewBidDetails: view === 'quote details' ? true : false,
                    viewUsers: view === 'users' ? true : false,
                    selectedUser:
                        view === 'user details' ||
                        view === 'edit user' ||
                        view === 'change role'
                            ? data
                            : false,
                    viewUserDetails: view === 'user details' ? true : false,
                    viewApplications: view === 'applications' ? true : false,
                    viewApplicationDetails:
                        view === 'application details' ? true : false,
                    viewSettings: view === 'settings' ? true : false,
                    selectedApplication:
                        view === 'application details' ? data : false,
                    newOrder: view === 'create order' ? true : false,
                    newBid: view === 'create quote' ? true : false,
                    viewMessages: view === 'messages' ? true : false,
                    viewMaterials: view === 'materials' ? true : false,
                    viewTemplates: view === 'templates' ? true : false,
                    viewAutomation: view === 'automation' ? true : false,
                    viewTasks: view === 'reminders' ? true : false,
                    viewTaskDetails: view === 'reminder details' ? true : false,
                    selectedTask: view === 'reminder details' ? data : false,
                    viewQRCodes: view === 'qr codes' ? true : false,
                    viewQRCodeDetails:
                        view === 'qr code details' ? true : false,
                    selectedQRCode: view === 'qr code details' ? data : false,
                    newItem: false,
                    newRule: false,
                    allImages: [],
                    currentImagePage: 1,
                    userSearch: '',
                    orderSearch: '',
                    bidSearch: '',
                    itemSearch: '',
                    qrCodeSearch: '',
                    applicationSearch: '',
                },
                async () => {
                    window.scrollTo(0, 0);
                    if (view === 'user details') {
                        try {
                            const orders = await props.getOrders(
                                `status=complete&customer=${data._id}`,
                                true,
                            );

                            setCompletedImages(orders);
                        } catch (err) {
                            window.alert(
                                'Something went wrong, we are working on a fix now!',
                            );
                        }
                    }

                    updateState({ loadingModalIsOpen: false });
                },
            );
        });
    };

    const setPlan = async (user) => {
        if (user.payment_info && user.payment_info.plan_id)
            return await props.getSubscription(user.payment_info.plan_id);
        await props.setSubscription({});
    };

    const getDateRange = async () => {
        let range;
        if (state.renderDateRangePicker) {
            range = {
                startDate: props.range.startDate,
                endDate: props.range.endDate,
            };
        } else {
            range = { startDate: 'none' };
            if (state.dateLimit === 'today')
                range.endDate = moment().add(1, 'day').startOf('day');
            if (state.dateLimit === 'week')
                range.endDate = moment().add(7, 'days').endOf('day');
            if (state.dateLimit === 'month')
                range.endDate = moment().add(30, 'days').endOf('day');
        }

        return range;
    };

    const setCompletedImages = (orders) => {
        let images = [];
        orders.data.list &&
            orders.data.list.forEach((order) => {
                if (order.status === 'complete') {
                    order.images.forEach((image) => {
                        image.date = order.date;
                        images.push(image);
                    });
                }
            });

        updateState({ allImages: images });
    };

    useEffect(() => {
        let debounceSearch = null;
        let resetPagination = {};
        const search = () => {
            updateState({ loadingModalIsOpen: true }, () => {
                setTimeout(async () => {
                    if (state.viewOrders && props.orders.data) {
                        const range = await getDateRange();
                        const search = state.orderSearch
                            ? `&search=${state.orderSearch}`
                            : '';

                        await props.getOrders(
                            `status=${props.status.type}&page=${1}&limit=${
                                state.pageLimit
                            }${
                                props.status.type === 'pending'
                                    ? `&start=${range.startDate}&end=${range.endDate}`
                                    : ''
                            }${
                                state.selectedVendor !== 'all'
                                    ? `&vendor=${state.selectedVendor}`
                                    : ''
                            }${
                                state.orderType !== 'all'
                                    ? `&type=${state.orderType}`
                                    : ''
                            }` + search,
                        );

                        resetPagination = {
                            currentOrderPage: 1,
                        };
                    } else if (state.viewBids) {
                        const search = state.bidSearch
                            ? `&search=${state.bidSearch}`
                            : '';
                        await props.getBids(
                            `status=${props.bidStatus.type}&page=${1}&limit=${state.pageLimit}` +
                                search,
                        );

                        resetPagination = {
                            currentBidPage: 1,
                        };
                    } else if (state.viewMaterials) {
                        const itemSearch = state.itemSearch
                            ? `search=${state.itemSearch}`
                            : '';
                        await props.getItems(itemSearch);
                    } else if (state.viewAutomation) {
                        const ruleSearch = state.ruleSearch
                            ? `search=${state.ruleSearch}`
                            : '';
                        await props.getRules(ruleSearch);
                    } else if (state.viewUsers) {
                        const userSearch = state.userSearch
                            ? `search=${state.userSearch}`
                            : '';
                        await props.getUsers(userSearch);
                    } else if (state.viewApplications) {
                        const applicationSearch = state.applicationSearch
                            ? `search=${state.applicationSearch}`
                            : '';
                        await props.getApplications(applicationSearch);
                    } else if (state.viewQRCodes) {
                        const qrCodeSearch = state.qrCodeSearch
                            ? `search=${state.qrCodeSearch}`
                            : '';
                        await props.getQRCodes(qrCodeSearch);
                    } else if (state.viewTemplates) {
                        const templateSearch = state.templateSearch
                            ? `search=${state.templateSearch}`
                            : '';
                        await props.getTemplates(templateSearch);
                    } else if (state.viewTasks) {
                        let query = `status=${props.taskStatus.type}`;
                        if (state.taskSearch)
                            query += `&search=${state.taskSearch}`;
                        await props.getTasks(query);
                    }

                    updateState({
                        loadingModalIsOpen: false,
                        ...resetPagination,
                    });
                }, 1000);
            });
        };

        if (!debounceSearch) {
            debounceSearch = debounce(search, 1000);
        }

        debounceSearch();

        return () => {
            if (debounceSearch) {
                debounceSearch.cancel();
                debounceSearch = null;
            }
        };
    }, [
        state.orderSearch,
        state.bidSearch,
        state.itemSearch,
        state.ruleSearch,
        state.userSearch,
        state.applicationSearch,
        state.qrCodeSearch,
        state.taskSearch,
        state.templateSearch,
    ]);

    const updateFilter = async (status, type) => {
        if (type === 'orders') {
            await props.updateOrderStatus(status);
            updateState(
                {
                    loadingModalIsOpen: true,
                    currentOrderPage: 1,
                    orderStatus: status,
                },
                () => {
                    setTimeout(async () => {
                        const range = await getDateRange();
                        let query = `status=${status}&page=1&limit=${
                            state.pageLimit
                        }${
                            status === 'pending'
                                ? `&start=${range.startDate}&end=${range.endDate}`
                                : ''
                        }${
                            state.selectedVendor !== 'all'
                                ? `&vendor=${state.selectedVendor}`
                                : ''
                        }${
                            state.orderType !== 'all'
                                ? `&type=${state.orderType}`
                                : ''
                        }${
                            state.orderSearch
                                ? `&search=${state.orderSearch}`
                                : ''
                        }`;

                        await props.getOrders(query);
                        updateState({
                            orderSearch: '',
                            loadingModalIsOpen: false,
                        });
                    }, 1000);
                },
            );
        }

        if (type === 'bids') {
            await props.updateBidStatus(status);
            updateState({ loadingModalIsOpen: true }, () => {
                setTimeout(async () => {
                    let query = `status=${status}&page=${
                        state.currentBidPage
                    }&limit=${state.pageLimit}${
                        state.bidSearch ? `&search=${state.bidSearch}` : ''
                    }`;
                    await props.getBids(query);
                    updateState({
                        loadingModalIsOpen: false,
                    });
                }, 1000);
            });
        }

        if (type === 'tasks') {
            await props.updateTaskStatus(status);
            updateState({ loadingModalIsOpen: true }, () => {
                setTimeout(async () => {
                    let query = `status=${status}${
                        state.taskSearch ? `&search=${state.taskSearch}` : ''
                    }`;
                    await props.getTasks(query);
                    updateState({
                        loadingModalIsOpen: false,
                    });
                }, 1000);
            });
        }
    };

    const updateRangeFilter = async (range) => {
        if (range.startDate) await props.updateRange(range);
        updateState(
            {
                loadingModalIsOpen: true,
                currentOrderPage: 1,
                dateRangePickerIsOpen: false,
            },
            () => {
                setTimeout(async () => {
                    const startDate = range.startDate
                        ? range.startDate
                        : 'none';

                    await props.getOrders(
                        `status=${props.status.type}&start=${startDate}&end=${
                            range.endDate
                        }&page=1&limit=${state.pageLimit}${
                            state.orderSearch
                                ? `&search=${state.orderSearch}`
                                : ''
                        }${
                            state.selectedVendor !== 'all'
                                ? `&vendor=${state.selectedVendor}`
                                : ''
                        }`,
                    );

                    updateState({
                        loadingModalIsOpen: false,
                    });
                }, 1000);
            },
        );
    };

    const updateVendorFilter = (e) => {
        const value = e.target.value;
        updateState({ loadingModalIsOpen: true, selectedVendor: value }, () => {
            setTimeout(async () => {
                const range = await getDateRange();
                await props.getOrders(
                    `status=${props.status.type}${
                        props.status.type === 'pending'
                            ? `&start=${range.startDate}&end=${range.endDate}`
                            : ''
                    }&page=${1}&limit=${state.pageLimit}${
                        state.orderType !== 'all'
                            ? `&type=${state.orderType}`
                            : ''
                    }${
                        state.orderSearch ? `&search=${state.orderSearch}` : ''
                    }${value !== 'all' ? `&vendor=${value}` : ''}`,
                );

                updateState({
                    loadingModalIsOpen: false,
                });
            }, 1000);
        });
    };

    const updateOrderType = async (e) => {
        const value = e.target.value;
        updateState({ loadingModalIsOpen: true, orderType: value }, () => {
            setTimeout(async () => {
                const range = await getDateRange();
                await props.getOrders(
                    `status=${props.status.type}&page=1&limit=${
                        state.pageLimit
                    }${
                        props.status.type === 'pending'
                            ? `&start=${range.startDate}&end=${range.endDate}`
                            : ''
                    }${value !== 'all' ? `&type=${value}` : ''}${
                        state.orderSearch ? `&search=${state.orderSearch}` : ''
                    }${
                        state.selectedVendor !== 'all'
                            ? `&vendor=${state.selectedVendor}`
                            : ''
                    }`,
                );

                updateState({
                    loadingModalIsOpen: false,
                });
            }, 1000);
        });
    };

    const deleteTemplate = async (id) => {
        if (window.confirm('Are you sure?')) {
            updateState({ loadingModalIsOpen: true }, () => {
                setTimeout(async () => {
                    await props.deleteTemplate(id);
                    await props.getTemplates();
                    updateState({ loadingModalIsOpen: false });
                }, 1000);
            });
        }
    };

    const duplicateTemplate = async (template) => {
        updateState({ loadingModalIsOpen: true }, () => {
            setTimeout(async () => {
                let newTemplate = template;
                newTemplate.name = `${template.name} COPY - PLEASE UPDATE TEMPLATE NAME`;
                delete newTemplate.dt_created;
                delete newTemplate._id;
                await props.createTemplate(newTemplate);
                await props.getTemplates();
                updateState({ loadingModalIsOpen: false });
            }, 1000);
        });
    };

    const saveRule = () => {
        updateState({ loadingModalIsOpen: true }, () => {
            setTimeout(async () => {
                const newRule = {
                    item_qty: parseFloat(state.itemQty),
                    unit_qty: parseFloat(state.unitQty),
                    unit: state.unitType,
                    product: state.selectedProduct,
                };

                if (state.ruleBuilderTitle === 'Create') {
                    newRule.key = state.selectedItem._id;

                    const rule = await props.getRules(
                        `key=${state.selectedItem._id}&product=${state.selectedProduct}`,
                        true,
                    );

                    if (rule.data.length > 0) {
                        updateState({ loadingModalIsOpen: false });
                        return window.alert(
                            'A rule already exists for this item and product',
                        );
                    }

                    await props.createRule(newRule);
                } else if (state.ruleBuilderTitle === 'Edit') {
                    await props.updateRule(state.selectedRule._id, newRule);
                }

                await props.getRules();
                updateState({
                    newRule: false,
                    loadingModalIsOpen: false,
                    itemName: '',
                    unitType: '',
                    unitQty: '',
                });
            }, 1000);
        });
    };

    const setRule = (rule) => {
        updateState({
            newRule: true,
            ruleBuilderTitle: 'Edit',
            selectedRule: rule,
            itemName: rule.key,
            itemQty: rule.item_qty,
            unitQty: rule.unit_qty,
            unitType: rule.unit,
            selectedProduct: rule.product._id,
        });
    };

    const deleteRule = (id) => {
        if (window.confirm('Are you sure?')) {
            updateState({ loadingModalIsOpen: true }, () => {
                setTimeout(async () => {
                    await props.deleteRule(id);
                    await props.getRules();
                    updateState({ loadingModalIsOpen: false });
                }, 1000);
            });
        }
    };

    const saveItem = () => {
        updateState({ loadingModalIsOpen: true }, () => {
            setTimeout(async () => {
                const newItem = {
                    name: state.itemName,
                    url: state.itemUrl,
                    price: state.itemPrice,
                };

                if (state.itemBuilderTitle === 'Create') {
                    await props.createItem(newItem);
                } else if (state.itemBuilderTitle === 'Edit') {
                    await props.updateItem(state.selectedItem._id, newItem);
                }

                await props.getItems();
                updateState({
                    newItem: false,
                    loadingModalIsOpen: false,
                });
            }, 1000);
        });
    };

    const setItem = (item) => {
        updateState({
            newItem: true,
            itemBuilderTitle: 'Edit',
            selectedItem: item,
            itemName: item.name,
            itemUrl: item.url,
            itemPrice: item.price,
        });
    };

    const deleteItem = (id) => {
        if (window.confirm('Are you sure?')) {
            updateState({ loadingModalIsOpen: true }, () => {
                setTimeout(async () => {
                    // get rules
                    const rules = await props.getRules(`key=${id}`, true);
                    if (rules.data.length > 0) {
                        updateState({ loadingModalIsOpen: false });
                        return window.alert(
                            'Cannot delete, there is an active rule for this item',
                        );
                    }

                    // delete item
                    await props.deleteItem(id);

                    // get items
                    await props.getItems();

                    updateState({ loadingModalIsOpen: false });
                }, 1000);
            });
        }
    };

    const setVendor = async (id, type) => {
        const { selectedOrder, selectedBid } = state;
        const {
            updateOrder,
            getOrder,
            getUser,
            sendEmail,
            sendSms,
            updateBid,
            getBid,
        } = props;

        if (window.confirm('Are you sure?')) {
            if (type === 'order') {
                await updateOrder(selectedOrder._id, { vendorId: id });
                if (id !== 'none') {
                    const vendor = await getUser(id, true);
                    const notification = {
                        email: vendor.data.email,
                        subject: `Yarden - New work order`,
                        label: 'Order Assignment',
                        body: `<p>Greetings from Yarden! You have been assigned a new work order scheduled for ${moment(
                            selectedOrder.date,
                        ).format('MM-DD-YYYY')} in ${
                            selectedOrder.customer.city
                        }, ${
                            selectedOrder.customer.state
                        }. The details are in your dashboard at ${
                            window.location.origin
                        }/dashboard</p>`,
                    };

                    await sendEmail(notification);

                    let sms = {
                        from: '8888289287',
                        to: vendor.data.phone_number.replace(/\D/g, ''),
                        body: `Greetings from Yarden! You have been assigned a new work order scheduled for ${moment(
                            selectedOrder.date,
                        ).format('MM-DD-YYYY')} in ${
                            selectedOrder.customer.city
                        }, ${
                            selectedOrder.customer.state
                        }. The details are in your dashboard at ${
                            window.location.origin
                        }/dashboard`,
                    };

                    await sendSms(sms);
                }

                let order = await getOrder(selectedOrder._id);
                updateState({
                    selectedOrder: order.data,
                });
            } else if (type === 'bid') {
                await updateBid(selectedBid._id, { vendor: id });
                if (id !== 'none') {
                    const vendor = await getUser(id, true);
                    const notification = {
                        email: vendor.data.email,
                        subject: `Yarden - New bid`,
                        label: 'Bid Assignment',
                        body: `<p>Greetings from Yarden! You have been assigned a new bid in ${selectedBid.customer.city}, ca. The details are in your dashboard at ${window.location.origin}/dashboard</p>`,
                    };

                    await sendEmail(notification);

                    const sms = {
                        from: '8888289287',
                        to: vendor.data.phone_number.replace(/\D/g, ''),
                        body: `Greetings from Yarden! You have been assigned a new bid in ${selectedBid.customer.city}, ca. The details are in your dashboard at ${window.location.origin}/dashboard`,
                    };

                    await sendSms(sms);
                }

                const bid = await getBid(selectedBid._id);
                updateState({
                    selectedBid: bid.data,
                });
            }
        }
    };

    const cancelOrder = async () => {
        const { selectedOrder } = state;
        const { updateOrder } = props;

        if (window.confirm('Are you sure?')) {
            await updateOrder(selectedOrder._id, { status: 'cancelled' });
            handleContentClick('Orders');
        }
    };

    const updateCurrentOrderPage = (page) => {
        updateState({ loadingModalIsOpen: true }, async () => {
            const range = await getDateRange();
            let query = `status=${props.status.type}&page=${page}&limit=${
                state.pageLimit
            }${
                props.status.type === 'pending'
                    ? `&start=${range.startDate}&end=${range.endDate}`
                    : ''
            }${
                state.selectedVendor !== 'all'
                    ? `&vendor=${state.selectedVendor}`
                    : ''
            }${state.orderType !== 'all' ? `&type=${state.orderType}` : ''}`;

            if (state.orderSearch) query += `&search=${state.orderSearch}`;
            await props.getOrders(query);
            updateState({ currentOrderPage: page, loadingModalIsOpen: false });
        });
    };

    const updateCurrentBidPage = (page) => {
        updateState({ loadingModalIsOpen: true }, async () => {
            let query = `status=${props.bidStatus.type}&page=${page}&limit=${state.pageLimit}`;
            if (state.bidSearch) query += `&search=${state.bidSearch}`;
            await props.getBids(query);
            updateState({ currentBidPage: page, loadingModalIsOpen: false });
        });
    };

    const updateCurrentImagePage = (page) => {
        updateState(
            { loadingModalIsOpen: true, currentImagePage: page },
            () => {
                setTimeout(async () => {
                    updateState({
                        loadingModalIsOpen: false,
                    });
                }, 1000);
            },
        );
    };

    const cancelBid = async () => {
        const { selectedBid } = state;
        const { updateBid } = props;

        if (window.confirm('Are you sure?')) {
            await updateBid(selectedBid._id, { status: 'cancelled' });
            handleContentClick('Quotes');
        }
    };

    const resendBid = async () => {
        if (window.confirm('Are you sure?')) {
            await calculateCosts();
            sendBid();
        }
    };

    const calculateCosts = async () => {
        const {
            selectedBid,
            materials,
            hours,
            rate,
            deliveryRequired,
            deliveryFee,
            deliveryItems,
            rentalsRequired,
            rentalFee,
            rentalItems,
            disposalRequired,
            disposalFee,
            disposalItems,
        } = state;

        if (!selectedBid) {
            window.alert('Please select a quote');
            return false;
        }

        const materialsTotal = await calculateMaterials(materials);

        const laborTotal = hours * rate;
        if (laborTotal < 1) {
            window.alert('Please enter a labor fee');
            return false;
        }

        let deliveryTotal = 0;
        if (deliveryRequired) {
            deliveryTotal = parseFloat(deliveryFee);
            if (!deliveryItems || deliveryTotal < 1 || isNaN(deliveryTotal)) {
                window.alert('Please enter a delivery description and price');
                return false;
            }
        }

        let rentalTotal = 0;
        if (rentalsRequired) {
            rentalTotal = parseFloat(rentalFee);
            if (!rentalItems || rentalTotal < 1 || isNaN(rentalTotal)) {
                window.alert('Please enter a rental description and price');
                return false;
            }
        }

        let disposalTotal = 0;
        if (disposalRequired) {
            disposalTotal = parseFloat(disposalFee);
            if (!disposalItems || disposalTotal < 1 || isNaN(disposalTotal)) {
                window.alert('Please enter a rental description and price');
                return false;
            }
        }

        updateState({
            materialsTotal,
            laborTotal,
            deliveryTotal,
            rentalTotal,
            disposalTotal,
        });

        return true;
    };

    const calculateMaterials = (materials) => {
        let materialsTotal = 0;
        if (materials) {
            materials.forEach((item) => {
                materialsTotal += item.price * item.qty;
            });
        }

        return materialsTotal;
    };

    const sendBid = async () => {
        const {
            bidType,
            estimatedStartDate,
            rate,
            hours,
            materials,
            deliveryRequired,
            deliveryItems,
            deliveryFee,
            rentalsRequired,
            rentalItems,
            rentalFee,
            disposalItems,
            disposalFee,
            disposalRequired,
            selectedBid,
        } = state;
        const {
            shapes,
            beds,
            updateBid,
            getBids,
            sendEmail,
            sendSms,
            getAccount,
        } = props;

        updateState({ loadingModalIsOpen: true });

        const bid = {
            status: 'pending approval',
            type: bidType,
            estimated_start_dt: estimatedStartDate,
            line_items: {
                labor: { name: 'hourly rate', price: rate, qty: hours },
            },
        };

        const rectangle = shapes.data.find(
            (shape) => shape.name === 'rectangle',
        );

        if (calculateMaterials(materials) > 1)
            bid.line_items.materials = materials;
        if (deliveryRequired)
            bid.line_items.delivery = {
                name: deliveryItems,
                price: deliveryFee,
            };
        if (rentalsRequired)
            bid.line_items.rentals = { name: rentalItems, price: rentalFee };
        if (disposalRequired)
            bid.line_items.disposal = {
                name: disposalItems,
                price: disposalFee,
            };

        if (bidType === 'installation' || bidType === 'revive') {
            let newBeds = beds;
            newBeds.forEach((bed) => {
                // NOTE: This is temporary for V1 release of garden map, need to change in the future to support all shapes
                // Author: Isaac G. 3/14/23
                // bed.shape = bed.shape._id;

                bed.shape = rectangle._id;
            });

            bid.line_items.beds = newBeds;
        }

        await updateBid(selectedBid._id, bid);

        let bidReadyToSend = true;
        let urlParams = `bid=${selectedBid._id}`;
        if (selectedBid.job) {
            urlParams = `job=${selectedBid.job}`;
            const bids = await getBids(`job=${selectedBid.job}`);
            bids.data.list.forEach((bid) => {
                if (
                    bid.status === 'bid requested' &&
                    bid._id !== selectedBid._id
                ) {
                    bidReadyToSend = false;
                }
            });
        }

        if (bidReadyToSend) {
            const customer = selectedBid.customer;
            const quoteUrl = `${window.location.origin}/approve?${urlParams}`;
            let emailMessage =
                `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                `<p style="margin-bottom: 15px">You received a new quote, please view it by logging into the Yarden app and going to the "Quotes" tab.</p>` +
                `<p>Alternatively, you can also access your quote via Yarden's website using this <a href="${quoteUrl}">link</a>.</p>`;

            let textMessage = `Greetings from Yarden! You received a new quote, please view it by logging into the Yarden app and going to the "Quotes" tab. Alternatively, you can also access your quote via Yarden's website: ${quoteUrl}`;
            const account = await getAccount(selectedBid.customer._id);
            if (account.data && account.data.status === 'pending') {
                const redirect = `${window.location.origin}/set-password?${urlParams}&user=${customer._id}`;
                emailMessage =
                    `<p>Greetings from Yarden!</p>` +
                    `<p>You have a new quote, please click the button below to view the details. If you have questions, please contact us at <a href="mailto:info@yardengarden.com">info@yardengarden.com</a>.</p>` +
                    '<div style="margin-top: 15px; border-top: 1px solid #DDDDDD;">' +
                    '<div style="padding: 15px; margin-top: 25px;">' +
                    `<a href="${redirect}" style="padding: 15px 30px; background-color: #509CFF; color: #FFFFFF; width: 100%;"><b>VIEW QUOTE</b></a>` +
                    '</div>' +
                    '</div>';

                textMessage = `Greetings from Yarden! You received a new quote: ${redirect}`;
            }

            const notification = {
                email: customer.email,
                subject: `Yarden - (ACTION REQUIRED) New quote`,
                label: 'New Quote',
                body: emailMessage,
            };

            await sendEmail(notification);

            const sms = {
                from: '8888289287',
                to: customer.phone_number.replace(/\D/g, ''),
                body: textMessage,
            };

            await sendSms(sms);
        }

        updateState({ loadingModalIsOpen: false });
    };

    const viewImageDetails = (img) => {
        updateState({
            imageModalIsOpen: true,
            selectedImage: img,
        });
    };

    const updateUserSettings = async () => {
        const { updateUser } = props;
        const {
            userFirstName,
            userLastName,
            userEmail,
            userPhoneNumber,
            selectedUser,
        } = state;
        const updatedUserSettings = {
            userFirstName: userFirstName
                ? userFirstName.trim()
                : selectedUser.first_name,
            userLastName: userLastName
                ? userLastName.trim()
                : selectedUser.last_name,
            userEmail: userEmail ? userEmail.trim() : selectedUser.email,
            userPhoneNumber: userPhoneNumber
                ? userPhoneNumber.replace(/\D/g, '')
                : selectedUser.phone_number,
        };

        const updatedUser = await updateUser(
            `userId=${selectedUser._id}`,
            updatedUserSettings,
        );
        handleContentClick('User Details', updatedUser.data);
    };

    const deleteAccount = () => {
        const { updateUser, logout, user } = props;
        const { selectedUser } = state;

        if (window.confirm('Are you sure?')) {
            updateState({ loadingModalIsOpen: true }, () => {
                setTimeout(async () => {
                    await updateUser(`userId=${selectedUser._id}`, {
                        dtDeleted: new Date(),
                    });
                    updateState({ loadingModalIsOpen: false });
                    if (user.data.type === PARTNER) {
                        logout();
                    } else {
                        handleContentClick('Users');
                    }
                }, 1000);
            });
        }
    };

    const updateActiveVendorStatus = (status) => {
        const { updateUser, getUsers } = props;
        const { selectedUser } = state;
        if (window.confirm('Are you sure?')) {
            updateState({ loadingModalIsOpen: true }, () => {
                setTimeout(async () => {
                    const updatedUser = await updateUser(
                        `userId=${selectedUser._id}`,
                        {
                            activeVendor: status,
                        },
                    );

                    await getUsers();

                    updateState({
                        loadingModalIsOpen: false,
                        selectedUser: updatedUser.data,
                    });
                }, 1000);
            });
        }
    };

    const renderCreateOrder = () => {
        return (
            <div className="tab-content">
                <NewOrder onClose={() => handleContentClick('Orders')} />
            </div>
        );
    };

    const renderCreateBid = () => {
        return (
            <div className="tab-content">
                <NewBid
                    onCreateBid={async (bid) => {
                        handleContentClick('Edit Quote', bid);
                    }}
                />
            </div>
        );
    };

    const renderOrders = () => {
        const {
            renderDateRangePicker,
            dateLimit,
            dateRangePickerIsOpen,
            selectedVendor,
            mapData,
            orderType,
            orderStatus,
            showOrderFilters,
            showMap,
        } = state;

        const { user, users, status, range, orders } = props;

        const dateRanges = [
            {
                label: 'Today',
                startDate: moment().startOf('day'),
                endDate: moment().add(1, 'day').startOf('day'),
            },
            {
                label: 'This Week',
                startDate: moment().startOf('week').startOf('day'),
                endDate: moment().endOf('week').endOf('day'),
            },
            {
                label: 'This Month',
                startDate: moment().startOf('month').startOf('day'),
                endDate: moment().endOf('month').endOf('day'),
            },
        ];

        const orderRows =
            orders.data && orders.data.list
                ? orders.data.list.map((order) => {
                      return {
                          tableData: {
                              customer: `${order.customer.first_name} ${order.customer.last_name}`,
                              type: (
                                  <span className={getBadgeStyles(order)}>
                                      {order.type}
                                  </span>
                              ),
                              date: moment(order.date).format('MM/DD/YYYY'),
                              time: order.time
                                  ? moment(order.time, `HH:mm:ss`).format(
                                        'hh:mm A',
                                    )
                                  : 'N/A',
                              address: order.customer.address,
                              city: order.customer.city,
                              state: (
                                  <span style={{ textTransform: 'uppercase' }}>
                                      {order.customer.state}
                                  </span>
                              ),
                              zip_code: order.customer.zip_code,
                              vendor: order.vendor
                                  ? `${order.vendor.first_name} ${order.vendor.last_name}`
                                  : '--',
                              _id: order._id,
                          },
                          originalData: order,
                      };
                  })
                : [];

        const orderColumns = [
            {
                accessor: 'type',
                Header: 'Order Type',
            },
            {
                accessor: 'customer',
                Header: 'Member',
            },
            {
                accessor: 'vendor',
                Header: 'Vendor',
            },
            {
                accessor: 'date',
                Header: 'Date',
            },
            {
                accessor: 'time',
                Header: 'Time',
            },
            {
                accessor: 'address',
                Header: 'Address',
            },
            {
                accessor: 'city',
                Header: 'City',
            },
            {
                accessor: 'state',
                Header: 'State',
            },
            {
                accessor: 'zip_code',
                Header: 'Zip Code',
            },
        ];

        if (orders.data) {
            return (
                <div className="tab-content">
                    <div className="flex flex-center-y flex-space-between">
                        <div className="flex flex-center-y">
                            <ToggleSwitch
                                value={showMap}
                                onToggle={() =>
                                    updateState({ showMap: !showMap })
                                }
                            />
                            <span className="ml5">
                                {showMap ? 'Hide' : 'Show'} Map
                            </span>
                        </div>
                        <button
                            className={`${
                                showOrderFilters ? 'btn2' : 'btn3'
                            } small flex flex-center-y`}
                            onClick={() =>
                                updateState({
                                    showOrderFilters: !showOrderFilters,
                                })
                            }
                        >
                            <Icon>tune_outlined</Icon>{' '}
                            <span style={{ marginLeft: 8 }}>Filters</span>
                        </button>
                    </div>
                    <div
                        className="toolbar"
                        style={{
                            overflow: 'unset',
                            display: showOrderFilters ? null : 'none',
                        }}
                    >
                        {/* date filter */}
                        <div
                            className={
                                !renderDateRangePicker
                                    ? 'block flex-1'
                                    : 'hidden'
                            }
                        >
                            <div>
                                <label>Date Range</label>
                            </div>
                            <select
                                className="full-width"
                                disabled={
                                    status.type === 'complete' ||
                                    renderDateRangePicker
                                }
                                name="status"
                                value={dateLimit}
                                onChange={(e) => {
                                    let range = {
                                        endDate: moment()
                                            .add(1, 'day')
                                            .startOf('day'),
                                    };
                                    updateState({ dateLimit: e.target.value });
                                    if (e.target.value === 'week')
                                        range = {
                                            endDate: moment()
                                                .add(7, 'days')
                                                .endOf('day'),
                                        };
                                    if (e.target.value === 'month')
                                        range = {
                                            endDate: moment()
                                                .add(30, 'days')
                                                .endOf('day'),
                                        };
                                    if (e.target.value === 'custom')
                                        return updateState({
                                            renderDateRangePicker: true,
                                        });
                                    updateRangeFilter(range);
                                }}
                            >
                                <option value="today">Today</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                                <option value="custom">Custom</option>
                            </select>
                        </div>

                        {/* custom date range */}
                        <div
                            className={
                                status.type !== 'complete' &&
                                renderDateRangePicker
                                    ? 'block flex-1'
                                    : 'hidden'
                            }
                        >
                            <div>
                                <label>Date Range</label>
                            </div>
                            <div
                                className="card flex flex-center-y full-width"
                                style={{
                                    padding: '8px 15px',
                                }}
                            >
                                <Icon
                                    fontSize="small"
                                    className="pointer text-blue1 mr5"
                                    title="Reset"
                                    onClick={async () => {
                                        const range = {
                                            endDate: moment()
                                                .add(1, 'day')
                                                .startOf('day'),
                                        };
                                        await updateState({
                                            renderDateRangePicker: false,
                                            dateLimit: 'today',
                                        });
                                        updateRangeFilter(range);
                                    }}
                                >
                                    cancel
                                </Icon>
                                <div
                                    className="date-range-picker"
                                    onClick={() =>
                                        updateState({
                                            dateRangePickerIsOpen:
                                                !dateRangePickerIsOpen,
                                        })
                                    }
                                >
                                    <div
                                        style={{
                                            fontSize: 'var(--h6)',
                                        }}
                                    >
                                        {moment(range.startDate).format(
                                            'MM/DD/YYYY',
                                        )}{' '}
                                        -{' '}
                                        {moment(range.endDate).format(
                                            'MM/DD/YYYY',
                                        )}
                                    </div>
                                </div>
                                <DateRangePicker
                                    closeOnClickOutside
                                    wrapperClassName={'calendar absolute z3'}
                                    definedRanges={dateRanges}
                                    open={dateRangePickerIsOpen}
                                    toggle={() =>
                                        updateState({
                                            dateRangePickerIsOpen:
                                                !dateRangePickerIsOpen,
                                        })
                                    }
                                    onChange={(range) =>
                                        updateRangeFilter(range)
                                    }
                                />
                            </div>
                        </div>

                        {/* vendor filters */}
                        <div
                            className={
                                user.data.type !== ADMIN
                                    ? 'hidden'
                                    : 'block flex-1'
                            }
                        >
                            <div>
                                <label>Vendor</label>
                            </div>
                            <select
                                className="full-width"
                                name="status"
                                value={selectedVendor}
                                onChange={(e) => updateVendorFilter(e)}
                            >
                                <option value="all">All Vendors</option>
                                {users.data &&
                                    users.data
                                        .filter(
                                            (user) =>
                                                (user.type === PARTNER &&
                                                    user.active_vendor) ||
                                                (user.type === GARDENER &&
                                                    user.active_vendor),
                                        )
                                        .map((user, index) => (
                                            <option
                                                value={user._id}
                                                key={index}
                                            >
                                                {`${user.first_name} ${user.last_name}`}
                                            </option>
                                        ))}
                            </select>
                        </div>

                        {/* order type filters */}
                        <div
                            className={
                                user.data.type !== ADMIN
                                    ? 'hidden'
                                    : 'block flex-1'
                            }
                        >
                            <div>
                                <label>Type</label>
                            </div>
                            <select
                                className="full-width"
                                name="type"
                                value={orderType}
                                onChange={(e) => {
                                    updateOrderType(e);
                                }}
                            >
                                <option value="all">All Order Types</option>
                                <option value="yard assessment">
                                    Yard Assessment
                                </option>
                                <option value="installation">
                                    Installation
                                </option>
                                <option value="revive">Revive</option>
                                <option value="full plan">Full Plan</option>
                                <option value="assisted plan">
                                    Assisted Plan
                                </option>
                                <option value="initial planting">
                                    Initial Planting
                                </option>
                                <option value="crop rotation">
                                    Crop Rotation
                                </option>
                                <option value="soil analysis">
                                    Soil Analysis
                                </option>
                                <option value="soil refill">Soil Refill</option>
                                <option value="misc">Misc</option>
                            </select>
                        </div>

                        {/* order status filter */}
                        <div className="block flex-1">
                            <div>
                                <label>Status</label>
                            </div>
                            <select
                                className="full-width"
                                name="status"
                                value={orderStatus}
                                onChange={(e) => {
                                    updateFilter(e.target.value, 'orders');
                                }}
                            >
                                <option value="pending">Pending</option>
                                <option value="complete">Complete</option>
                            </select>
                        </div>
                    </div>
                    <div
                        className={
                            status.type === 'complete' || !state.showMap
                                ? 'hidden'
                                : 'map-container'
                        }
                    >
                        <div className="order-headlines">
                            <div className="headline-card">
                                <label>Total Orders</label>
                                <h4>
                                    <data>{orders.data.total}</data>
                                </h4>
                            </div>
                        </div>
                        <Map
                            isMarkerShown
                            data={mapData}
                            zoom={9}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCuURGByZa_CUwSfefSAoyv4a9gBUa01Go&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div className="map" />}
                            mapElement={
                                <div
                                    style={{ height: `100%` }}
                                    className="curved"
                                />
                            }
                        />
                    </div>
                    <div className="mt15">
                        <YardenTable
                            columns={orderColumns}
                            data={orderRows ? orderRows : []}
                            onSelect={(order) => {
                                handleContentClick('Order Details', order);
                            }}
                        />
                    </div>
                    <div className="mt15 mb15">
                        <Pagination
                            currentPage={state.currentOrderPage}
                            pageLimit={state.pageLimit}
                            totalCount={orders.data.total}
                            onChange={(page) => updateCurrentOrderPage(page)}
                        />
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderQuotes = () => {
        const { renderNewQuote } = state;
        const { user, bids, bidStatus } = props;

        const bidRows = bids.data
            ? bids.data.list.map((bid) => {
                  return {
                      tableData: {
                          type: bid.type,
                          dt_created: moment(bid.dt_created).format(
                              'MM/DD/YYYY',
                          ),
                          status: bid.status,
                          title: bid.title,
                          description: bid.description,
                          customer: `${bid.customer.first_name} ${bid.customer.last_name}`,
                          vendor: bid.vendor
                              ? `${bid.vendor.first_name} ${bid.vendor.last_name}`
                              : '---',
                      },
                      originalData: bid,
                  };
              })
            : [];

        const bidColumns = [
            {
                accessor: 'dt_created',
                Header: 'Date Created',
            },
            {
                accessor: 'customer',
                Header: 'Member',
            },
            {
                accessor: 'vendor',
                Header: 'Vendor',
            },

            {
                accessor: 'title',
                Header: 'Title',
            },
            {
                accessor: 'description',
                Header: 'Description',
            },
        ];

        if (bids.data) {
            return (
                <div className="tab-content">
                    <div className="toolbar quotes-toolbar">
                        {renderNewQuote && user.data.type === ADMIN && (
                            <div>
                                <select
                                    name="status"
                                    value="quoteType"
                                    onChange={(e) => {
                                        if (e.target.value === 'custom') {
                                            changeView('create quote');
                                        } else if (
                                            e.target.value === 'accessories'
                                        ) {
                                            updateState({
                                                newAccessoriesModalIsOpen: true,
                                            });
                                        }
                                    }}
                                >
                                    <option value="quoteType">
                                        Quote Type
                                    </option>
                                    <option value="custom">Custom Quote</option>
                                    <option value="accessories">
                                        Accessories
                                    </option>
                                </select>
                            </div>
                        )}
                        <div>
                            <select
                                name="status"
                                value={bidStatus.type}
                                onChange={(e) =>
                                    updateFilter(e.target.value, 'bids')
                                }
                            >
                                <option value="bid requested">
                                    Quote Requested
                                </option>
                                <option value="pending approval">
                                    Pending Approval
                                </option>
                                <option value="approved">Approved</option>
                            </select>
                        </div>
                    </div>
                    <YardenTable
                        columns={bidColumns}
                        data={bidRows ? bidRows : []}
                        onSelect={(bid) => {
                            handleContentClick('Quote Details', bid);
                        }}
                    />
                    <div className="mt15 mb15">
                        <Pagination
                            currentPage={state.currentBidPage}
                            pageLimit={state.pageLimit}
                            totalCount={bids.data.total}
                            onChange={(page) => updateCurrentBidPage(page)}
                        />
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderTemplates = () => {
        const { templates } = props;

        if (templates.data) {
            return (
                <div>
                    <div className="mt15">
                        <div className="bg-white border-bottom-purpleC-10 full-width pr15 pt10 pl15 pb10">
                            <h6>Template Type</h6>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <List className="p0">
                            {templates.data && templates.data.length > 0
                                ? templates.data.map((template, index) => {
                                      return (
                                          <span key={index}>
                                              <ListItem
                                                  button
                                                  className={`${
                                                      index % 2 !== 0
                                                          ? 'bg-white'
                                                          : 'bg-gray4'
                                                  } border-bottom-purpleC-10`}
                                                  onClick={() =>
                                                      updateState({
                                                          [index]:
                                                              !state[index],
                                                      })
                                                  }
                                              >
                                                  <ListItemText
                                                      primary={
                                                          <data>
                                                              {template.name}
                                                          </data>
                                                      }
                                                  />
                                                  {state[index] ? (
                                                      <ExpandLess />
                                                  ) : (
                                                      <ExpandMore />
                                                  )}
                                              </ListItem>
                                              <Collapse
                                                  in={state[index]}
                                                  timeout="auto"
                                                  unmountOnExit
                                              >
                                                  <List
                                                      component="div"
                                                      disablePadding
                                                  >
                                                      <ListItem
                                                          button
                                                          className="bg-white border-bottom-purpleC-10"
                                                          onClick={async () => {
                                                              handleContentClick(
                                                                  'Edit Template',
                                                                  template,
                                                              );
                                                          }}
                                                      >
                                                          <ListItemText
                                                              primary={
                                                                  <div className="flex flex-center-y">
                                                                      <EditOutlinedIcon />{' '}
                                                                      <span
                                                                          style={{
                                                                              marginLeft: 8,
                                                                          }}
                                                                      >
                                                                          Edit
                                                                      </span>
                                                                  </div>
                                                              }
                                                          />
                                                      </ListItem>
                                                      <ListItem
                                                          button
                                                          className="bg-white border-bottom-purpleC-10"
                                                          onClick={async () => {
                                                              await duplicateTemplate(
                                                                  template,
                                                              );
                                                              updateState({
                                                                  [index]:
                                                                      !state[
                                                                          index
                                                                      ],
                                                              });
                                                          }}
                                                      >
                                                          <ListItemText
                                                              primary={
                                                                  <div className="flex flex-center-y">
                                                                      <Icon>
                                                                          content_copy
                                                                      </Icon>{' '}
                                                                      <span
                                                                          style={{
                                                                              marginLeft: 8,
                                                                          }}
                                                                      >
                                                                          Duplicate
                                                                      </span>
                                                                  </div>
                                                              }
                                                          />
                                                      </ListItem>
                                                      <ListItem
                                                          button
                                                          className="bg-white border-bottom-purpleC-10"
                                                          onClick={async () => {
                                                              await deleteTemplate(
                                                                  template._id,
                                                              );
                                                              updateState({
                                                                  [index]:
                                                                      !state[
                                                                          index
                                                                      ],
                                                              });
                                                          }}
                                                      >
                                                          <ListItemText
                                                              primary={
                                                                  <div className="flex flex-center-y">
                                                                      <Icon>
                                                                          delete_outlined
                                                                      </Icon>{' '}
                                                                      <span
                                                                          style={{
                                                                              marginLeft: 8,
                                                                          }}
                                                                      >
                                                                          Delete
                                                                      </span>
                                                                  </div>
                                                              }
                                                          />
                                                      </ListItem>
                                                  </List>
                                              </Collapse>
                                          </span>
                                      );
                                  })
                                : null}
                        </List>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderAutomation = () => {
        const {
            newRule,
            ruleBuilderTitle,
            selectedRule,
            itemQty,
            unitQty,
            unitType,
            selectedProduct,
            selectedItem,
        } = state;

        const { rules, products, items } = props;

        if (rules.data && items.data && products.data) {
            return (
                <div>
                    <div className={newRule ? 'hidden' : null}>
                        <div>
                            <div className="mt15">
                                <div className="bg-white border-bottom-purpleC-10 full-width pr15 pt10 pl15 pb10">
                                    <h6>Rule</h6>
                                </div>
                            </div>
                            <div className="table-wrapper">
                                <List className="p0">
                                    {rules.data && rules.data.length > 0
                                        ? rules.data.map((rule, index) => {
                                              return (
                                                  <span key={index}>
                                                      <ListItem
                                                          button
                                                          className={`${
                                                              index % 2 !== 0
                                                                  ? 'bg-white'
                                                                  : 'bg-gray4'
                                                          } border-bottom-purpleC-10`}
                                                          onClick={() =>
                                                              updateState({
                                                                  [`rule-${index}`]:
                                                                      !state[
                                                                          `rule-${index}`
                                                                      ],
                                                              })
                                                          }
                                                      >
                                                          <ListItemText
                                                              primary={
                                                                  <div>
                                                                      <data>
                                                                          {truncate(
                                                                              rule
                                                                                  .key
                                                                                  .name,
                                                                              20,
                                                                          )}
                                                                      </data>
                                                                  </div>
                                                              }
                                                          />
                                                          {state[
                                                              `rule-${index}`
                                                          ] ? (
                                                              <ExpandLess />
                                                          ) : (
                                                              <ExpandMore />
                                                          )}
                                                      </ListItem>
                                                      <Collapse
                                                          in={
                                                              state[
                                                                  `rule-${index}`
                                                              ]
                                                          }
                                                          timeout="auto"
                                                          unmountOnExit
                                                      >
                                                          <List
                                                              component="div"
                                                              disablePadding
                                                          >
                                                              <ListItem
                                                                  button
                                                                  className="bg-white border-bottom-purpleC-10"
                                                                  onClick={() => {
                                                                      updateState(
                                                                          {
                                                                              [`rule-${index}`]:
                                                                                  !state[
                                                                                      `rule-${index}`
                                                                                  ],
                                                                          },
                                                                      );
                                                                      setRule(
                                                                          rule,
                                                                      );
                                                                  }}
                                                              >
                                                                  <ListItemText primary="Edit" />
                                                              </ListItem>
                                                              <ListItem
                                                                  button
                                                                  className="bg-white border-bottom-purpleC-10"
                                                                  onClick={() => {
                                                                      updateState(
                                                                          {
                                                                              [`rule-${index}`]:
                                                                                  !state[
                                                                                      `rule-${index}`
                                                                                  ],
                                                                          },
                                                                      );
                                                                      deleteRule(
                                                                          rule._id,
                                                                          index,
                                                                      );
                                                                  }}
                                                              >
                                                                  <ListItemText primary="Delete" />
                                                              </ListItem>
                                                          </List>
                                                      </Collapse>
                                                  </span>
                                              );
                                          })
                                        : null}
                                    {rules.data && rules.data.length < 1 && (
                                        <p className="mt25 text-center">
                                            No Rules
                                        </p>
                                    )}
                                </List>
                            </div>
                        </div>
                    </div>
                    <div className={newRule ? null : 'hidden'}>
                        <div>
                            <h5>{ruleBuilderTitle} Rule</h5>
                            <div>
                                <div>
                                    <label>Qty*</label>
                                    <input
                                        type="number"
                                        placeholder={`1`}
                                        name="itemQty"
                                        value={itemQty}
                                        onChange={(e) => setValue(e)}
                                    />
                                </div>
                                <div className="full">
                                    <div
                                        className={
                                            ruleBuilderTitle === 'Create'
                                                ? null
                                                : 'hidden'
                                        }
                                    >
                                        <label>Item Name*</label>
                                        <Autocomplete
                                            fullWidth
                                            autoComplete
                                            options={items.data.map(
                                                (item) => item.name,
                                            )}
                                            onChange={(e) => {
                                                const item = items.data.find(
                                                    (item) =>
                                                        item.name ===
                                                        e.target.innerText,
                                                );
                                                updateState({
                                                    selectedItem: item,
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    placeholder="Orbit 2-Dial Digital Timer 62040"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div
                                        className={
                                            ruleBuilderTitle === 'Edit'
                                                ? null
                                                : 'hidden'
                                        }
                                    >
                                        <label>Item Name (Cannot change)</label>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="Orbit 2-Dial Digital Timer 62040"
                                            value={
                                                selectedRule
                                                    ? selectedRule.key.name
                                                    : 'none'
                                            }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label>Units*</label>
                                    <input
                                        type="number"
                                        placeholder={`1`}
                                        name="unitQty"
                                        value={unitQty}
                                        onChange={(e) => setValue(e)}
                                    />
                                </div>
                                <div>
                                    <label>Unit Type*</label>
                                    <select
                                        name="unitType"
                                        value={unitType}
                                        onChange={async (e) => setValue(e)}
                                    >
                                        <option value="bid">
                                            quote (each)
                                        </option>
                                        <option value="sqft">
                                            square ft. (area)
                                        </option>
                                        <option value="cf">
                                            cubic ft. (depth)
                                        </option>
                                        <option value="vf">
                                            vertical ft. (height)
                                        </option>
                                        <option value="lf">
                                            linear ft. (length)
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label>Product*</label>
                                    <select
                                        name="selectedProduct"
                                        value={selectedProduct || ''}
                                        onChange={async (e) => setValue(e)}
                                    >
                                        <option value="">
                                            Select a product
                                        </option>
                                        {products.data.map((product, index) => (
                                            <option
                                                key={index}
                                                value={product._id}
                                            >
                                                {product.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mt15 flex flex-space-between">
                                <div></div>
                                <div>
                                    <button
                                        className="purple small flex flex-center-y"
                                        disabled={
                                            loadingModalIsOpen ||
                                            !itemQty ||
                                            !unitQty ||
                                            !unitType ||
                                            !selectedProduct ||
                                            (ruleBuilderTitle === 'Create' &&
                                                !selectedItem)
                                        }
                                        onClick={() => saveRule()}
                                    >
                                        <Icon>done</Icon>{' '}
                                        <span style={{ marginLeft: 8 }}>
                                            Finish
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderMaterials = () => {
        const { newItem, itemBuilderTitle, itemUrl, itemPrice, itemName } =
            state;

        const { items, products } = props;

        if (items.data && products.data) {
            return (
                <div className="tab-content">
                    <div className={newItem ? 'hidden' : null}>
                        <div className="bg-white border-bottom-purpleC-10 full-width pr15 pt10 pl15 pb10">
                            <h6>Item Name</h6>
                        </div>
                        <div className="materials-content">
                            <div className="table-wrapper">
                                <List className="p0">
                                    {items.data && items.data.length > 0
                                        ? items.data.map((item, index) => {
                                              return (
                                                  <span key={index}>
                                                      <ListItem
                                                          button
                                                          className={`${
                                                              index % 2 !== 0
                                                                  ? 'bg-white'
                                                                  : 'bg-gray4'
                                                          } border-bottom-purpleC-10`}
                                                          onClick={() =>
                                                              updateState({
                                                                  [index]:
                                                                      !state[
                                                                          index
                                                                      ],
                                                              })
                                                          }
                                                      >
                                                          <ListItemText
                                                              primary={
                                                                  <div>
                                                                      <data>
                                                                          {truncate(
                                                                              item.name,
                                                                              60,
                                                                          )}
                                                                      </data>
                                                                  </div>
                                                              }
                                                          />
                                                          {state[index] ? (
                                                              <ExpandLess />
                                                          ) : (
                                                              <ExpandMore />
                                                          )}
                                                      </ListItem>
                                                      <Collapse
                                                          in={state[index]}
                                                          timeout="auto"
                                                          unmountOnExit
                                                      >
                                                          <List
                                                              component="div"
                                                              disablePadding
                                                          >
                                                              <ListItem
                                                                  button
                                                                  className="bg-white border-bottom-purpleC-10"
                                                                  onClick={() => {
                                                                      updateState(
                                                                          {
                                                                              [index]:
                                                                                  !state[
                                                                                      index
                                                                                  ],
                                                                          },
                                                                      );
                                                                      setItem(
                                                                          item,
                                                                      );
                                                                  }}
                                                              >
                                                                  <ListItemText
                                                                      primary={
                                                                          <div className="flex flex-center-y">
                                                                              <EditOutlinedIcon />{' '}
                                                                              <span
                                                                                  style={{
                                                                                      marginLeft: 8,
                                                                                  }}
                                                                              >
                                                                                  Edit
                                                                              </span>
                                                                          </div>
                                                                      }
                                                                  />
                                                              </ListItem>
                                                              <ListItem
                                                                  button
                                                                  className="bg-white border-bottom-purpleC-10"
                                                                  onClick={() => {
                                                                      updateState(
                                                                          {
                                                                              [index]:
                                                                                  !state[
                                                                                      index
                                                                                  ],
                                                                          },
                                                                      );
                                                                      deleteItem(
                                                                          item._id,
                                                                      );
                                                                  }}
                                                              >
                                                                  <ListItemText
                                                                      primary={
                                                                          <div className="flex flex-center-y">
                                                                              <Icon>
                                                                                  delete_outlined
                                                                              </Icon>{' '}
                                                                              <span
                                                                                  style={{
                                                                                      marginLeft: 8,
                                                                                  }}
                                                                              >
                                                                                  Delete
                                                                              </span>
                                                                          </div>
                                                                      }
                                                                  />
                                                              </ListItem>
                                                          </List>
                                                      </Collapse>
                                                  </span>
                                              );
                                          })
                                        : null}
                                    {items.data && items.data.length < 1 && (
                                        <p className="mt25 text-center">
                                            No Items
                                        </p>
                                    )}
                                </List>
                            </div>
                        </div>{' '}
                    </div>
                    <div className={newItem ? null : 'hidden'}>
                        <div>
                            <h5>{itemBuilderTitle} Item</h5>
                            <label>Name</label>
                            <input
                                type="text"
                                name="itemName"
                                placeholder={`2" x 6" x 96 Lumber"`}
                                value={itemName}
                                onChange={(e) => setValue(e)}
                            />
                            <label>URL</label>
                            <input
                                type="text"
                                name="itemUrl"
                                placeholder={`https://www.homedepot.com`}
                                value={itemUrl}
                                onChange={(e) => setValue(e)}
                            />
                            <label>Price</label>
                            <input
                                type="number"
                                name="itemPrice"
                                placeholder="0"
                                value={itemPrice}
                                onChange={(e) => {
                                    let price;
                                    if (e.target.value.length < 1) {
                                        price = '';
                                    } else {
                                        price = !validateCurrency(
                                            e.target.value,
                                        )
                                            ? itemPrice
                                            : parseFloat(e.target.value);
                                    }

                                    updateState({ itemPrice: price });
                                }}
                            />
                            <div className="mt15 flex flex-space-between">
                                <div></div>
                                <div>
                                    <button
                                        className="purple small flex flex-center-y"
                                        disabled={
                                            loadingModalIsOpen ||
                                            !itemName ||
                                            !itemPrice
                                        }
                                        onClick={() => saveItem()}
                                    >
                                        <Icon>save</Icon>{' '}
                                        <span style={{ marginLeft: 8 }}>
                                            Save
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderUsers = () => {
        const { users } = props;
        const userRows = users.data
            ? users.data.map((user) => {
                  return {
                      tableData: {
                          type: user.type,
                          first_name: user.first_name,
                          last_name: user.last_name,
                          address: user.address,
                          city: user.city,
                          state: (
                              <span style={{ textTransform: 'uppercase' }}>
                                  {user.state}
                              </span>
                          ),
                          zip_code: user.zip_code,
                      },
                      originalData: user,
                  };
              })
            : [];

        const userColumns = [
            {
                accessor: 'type',
                Header: 'User Type',
            },
            {
                accessor: 'first_name',
                Header: 'First Name',
            },
            {
                accessor: 'last_name',
                Header: 'Last Name',
            },
            {
                accessor: 'address',
                Header: 'Address',
            },
            {
                accessor: 'city',
                Header: 'City',
            },
            {
                accessor: 'state',
                Header: 'State',
            },
            {
                accessor: 'zip_code',
                Header: 'Zip Code',
            },
        ];

        if (users.data) {
            return (
                <div className="tab-content">
                    <YardenTable
                        columns={userColumns}
                        data={userRows ? userRows : []}
                        onSelect={(user) => {
                            handleContentClick('User Details', user);
                        }}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderJobApplications = () => {
        const { applications } = props;
        const applicationRows = applications.data
            ? applications.data.map((application) => {
                  return {
                      tableData: {
                          dt_created: moment(application.dt_created).format(
                              'MM/DD/YYYY',
                          ),
                          status: application.status,
                          role: application.role,
                          first_name: application.first_name,
                          last_name: application.last_name,
                          address: application.address,
                          city: application.city,
                          state: (
                              <span style={{ textTransform: 'uppercase' }}>
                                  {application.state}
                              </span>
                          ),
                          zip_code: application.zip_code,
                      },
                      originalData: application,
                  };
              })
            : [];

        const jobApplicationColumns = [
            {
                accessor: 'dt_created',
                Header: 'Application Date',
            },
            {
                accessor: 'status',
                Header: 'Status',
            },
            {
                accessor: 'role',
                Header: 'Role',
            },
            {
                accessor: 'first_name',
                Header: 'First Name',
            },
            {
                accessor: 'last_name',
                Header: 'Last Name',
            },
            {
                accessor: 'address',
                Header: 'Address',
            },
            {
                accessor: 'city',
                Header: 'City',
            },
            {
                accessor: 'state',
                Header: 'State',
            },
            {
                accessor: 'zip_code',
                Header: 'Zip Code',
            },
        ];

        if (applications.data) {
            return (
                <div className="tab-content">
                    <YardenTable
                        columns={jobApplicationColumns}
                        data={applicationRows ? applicationRows : []}
                        onSelect={(application) => {
                            handleContentClick(
                                'Application Details',
                                application,
                            );
                        }}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderQRCodes = () => {
        const { qrCodes } = props;
        const qrCodeRows = qrCodes.data
            ? qrCodes.data.map((qrCode) => {
                  return {
                      tableData: {
                          source: qrCode.source,
                          variant: qrCode.variant,
                      },
                      originalData: qrCode,
                  };
              })
            : [];

        const qrCodeColumns = [
            {
                accessor: 'source',
                Header: 'Source',
            },
            {
                accessor: 'variant',
                Header: 'Variant',
            },
        ];

        if (qrCodes.data) {
            return (
                <div className="tab-content">
                    <YardenTable
                        columns={qrCodeColumns}
                        data={qrCodeRows ? qrCodeRows : []}
                        onSelect={(qrCode) => {
                            handleContentClick('QR Code Details', qrCode);
                        }}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderTasks = () => {
        const { tasks, taskStatus } = props;

        const taskRows =
            tasks.data && tasks.data.list
                ? tasks.data.list.map((task) => {
                      return {
                          tableData: {
                              date: moment(task.date).format('MM/DD/YYYY'),
                              time: moment(task.date).format('hh:mm A'),
                              customer: `${task.customer.first_name} ${task.customer.last_name}`,
                              status: task.status,
                              title: task.title,
                              description: task.description,
                              state: (
                                  <span style={{ textTransform: 'uppercase' }}>
                                      {task.customer.state}
                                  </span>
                              ),
                              _id: task._id,
                          },
                          originalData: task,
                      };
                  })
                : [];

        const taskColumns = [
            {
                accessor: 'date',
                Header: 'Date',
            },
            {
                accessor: 'time',
                Header: 'Time',
            },
            {
                accessor: 'customer',
                Header: 'Member',
            },
            {
                accessor: 'title',
                Header: 'Title',
            },
            {
                accessor: 'description',
                Header: 'Description',
            },
        ];

        if (tasks.data) {
            return (
                <div className="tab-content">
                    <div className="mb15">
                        <select
                            name="status"
                            className="fit-content"
                            value={taskStatus.type}
                            onChange={(e) =>
                                updateFilter(e.target.value, 'tasks')
                            }
                        >
                            <option value="pending">Pending</option>
                            <option value="complete">Complete</option>
                        </select>
                    </div>
                    <YardenTable
                        columns={taskColumns}
                        data={taskRows ? taskRows : []}
                        onSelect={(task) => {
                            handleContentClick('Reminder Details', task);
                        }}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };

    const renderMessages = () => {
        const { customers } = state;
        return (
            <div className="tab-content">
                <Messenger
                    customers={customers}
                    onConversationOpen={(id) =>
                        updateState({ conversationOpened: id })
                    }
                    onViewTasks={() => {
                        changeView('tasks');
                    }}
                />
            </div>
        );
    };

    const renderOrderDetails = () => {
        const { selectedOrder, specialRequest } = state;
        const { user, getOrder } = props;

        if (selectedOrder) {
            return (
                <div className="tab-content">
                    <OrderDetails
                        selectedOrder={selectedOrder}
                        specialRequest={specialRequest}
                        changeView={() => {
                            handleContentClick('Orders');
                        }}
                        setVendor={(id, type) => setVendor(id, type)}
                        onUpdateOrder={async () => {
                            const order = await getOrder(selectedOrder._id);
                            if (order) {
                                updateState({
                                    selectedOrder: order.data,
                                });
                            }
                        }}
                    />
                    {selectedOrder.images &&
                        selectedOrder.images.length > 0 && (
                            <div className="mt25 mb25">
                                <Divider />
                                <div className="mt25">
                                    <Images
                                        title="images"
                                        selectedOrder={selectedOrder}
                                        onUpload={async () => {
                                            const updatedOrder = await getOrder(
                                                selectedOrder._id,
                                            );
                                            updateState({
                                                selectedOrder:
                                                    updatedOrder.data,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    <div className="mt15">
                        <Divider />
                    </div>
                    <div
                        className={
                            selectedOrder.status === 'pending' &&
                            user.data.type === ADMIN
                                ? 'mt25'
                                : 'hidden'
                        }
                    >
                        <div className="pb25">
                            <button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 'var(--unit2)',
                                }}
                                className="text-red btn3 small mt15"
                                onClick={() => cancelOrder()}
                            >
                                <Icon>clear</Icon>
                                Cancel Order
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderBidDetails = () => {
        const {
            selectedBid,
            quoteLinkCopied,
            viewBidBuilderStep3,
            selectedOrder,
        } = state;
        const { users, user } = props;

        if (selectedBid) {
            const {
                laborTotal,
                materialsTotal,
                deliveryTotal,
                rentalTotal,
                disposalTotal,
                firstPayment,
                secondPayment,
            } = getQuotePricing(selectedBid);

            const salesTax = firstPayment.salesTax + secondPayment.salesTax;
            const paymentProcessingFee =
                firstPayment.paymentProcessingFee +
                secondPayment.paymentProcessingFee;
            const totalSale = firstPayment.total + secondPayment.total;

            return (
                <div className="tab-content">
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                            <div>
                                <div className="float-right mb15">
                                    <div
                                        className={
                                            selectedBid.status !==
                                            'bid requested'
                                                ? 'hidden'
                                                : 'full-width'
                                        }
                                    >
                                        <button
                                            className="btn3 small flex flex-center-y float-right"
                                            onClick={async () =>
                                                handleContentClick(
                                                    'Edit Quote',
                                                    selectedBid,
                                                )
                                            }
                                        >
                                            <EditOutlinedIcon />{' '}
                                            <span style={{ marginLeft: 8 }}>
                                                Edit Quote
                                            </span>
                                        </button>
                                    </div>
                                    <div className="flex">
                                        <div
                                            className={
                                                !selectedBid ||
                                                selectedBid.status !==
                                                    'pending approval'
                                                    ? 'hidden'
                                                    : 'mr5'
                                            }
                                        >
                                            <CopyToClipboard
                                                text={`${
                                                    window.location.origin
                                                }/approve?${
                                                    selectedBid.job
                                                        ? `job=${selectedBid.job}`
                                                        : `bid=${selectedBid._id}`
                                                }`}
                                            >
                                                <button
                                                    className="btn3 small flex flex-center-y"
                                                    onClick={() =>
                                                        updateState({
                                                            quoteLinkCopied: true,
                                                        })
                                                    }
                                                >
                                                    <Icon>link</Icon>
                                                    <span
                                                        style={{
                                                            marginLeft: 8,
                                                        }}
                                                    >
                                                        {quoteLinkCopied
                                                            ? 'Copied!'
                                                            : 'Copy Link'}
                                                    </span>
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                        <div
                                            className={
                                                !selectedBid ||
                                                selectedBid.status !==
                                                    'pending approval'
                                                    ? 'hidden'
                                                    : 'mr5'
                                            }
                                        >
                                            <button
                                                className="btn3 small flex flex-center-y"
                                                disabled={loadingModalIsOpen}
                                                onClick={async () =>
                                                    resendBid()
                                                }
                                            >
                                                <SendOutlined />
                                                <span style={{ marginLeft: 8 }}>
                                                    {loadingModalIsOpen
                                                        ? 'Sending...'
                                                        : 'Re-Send'}
                                                </span>
                                            </button>
                                        </div>
                                        <button
                                            className={
                                                !selectedBid ||
                                                selectedBid.status !==
                                                    'pending approval'
                                                    ? 'hidden'
                                                    : 'btn3 small flex flex-center-y'
                                            }
                                            disabled={loadingModalIsOpen}
                                            onClick={async () =>
                                                handleContentClick(
                                                    'Edit Quote',
                                                    selectedBid,
                                                )
                                            }
                                        >
                                            <EditOutlinedIcon />{' '}
                                            <span style={{ marginLeft: 8 }}>
                                                Edit
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12}>
                                        {selectedBid &&
                                            !viewBidBuilderStep3 && (
                                                <div>
                                                    <div
                                                        className={
                                                            user.data.type !==
                                                            ADMIN
                                                                ? 'hidden'
                                                                : 'mb50'
                                                        }
                                                    >
                                                        <label>Vendor</label>
                                                        <div className="mt5">
                                                            <select
                                                                className="fit-content"
                                                                value={
                                                                    selectedBid.vendor
                                                                        ? selectedBid
                                                                              .vendor
                                                                              ._id
                                                                        : 'none'
                                                                }
                                                                onChange={(e) =>
                                                                    setVendor(
                                                                        e.target
                                                                            .value,
                                                                        'bid',
                                                                    )
                                                                }
                                                            >
                                                                <option
                                                                    value={
                                                                        'none'
                                                                    }
                                                                >
                                                                    None
                                                                </option>
                                                                {users.data &&
                                                                    users.data
                                                                        .filter(
                                                                            (
                                                                                user,
                                                                            ) =>
                                                                                (user.type ===
                                                                                    PARTNER &&
                                                                                    user.active_vendor) ||
                                                                                (user.type ===
                                                                                    GARDENER &&
                                                                                    user.active_vendor),
                                                                        )
                                                                        .map(
                                                                            (
                                                                                user,
                                                                                index,
                                                                            ) => (
                                                                                <option
                                                                                    value={
                                                                                        user._id
                                                                                    }
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {`${user.first_name} ${user.last_name}`}
                                                                                </option>
                                                                            ),
                                                                        )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="data-detail">
                                                        <label
                                                            className={
                                                                selectedBid.estimated_start_dt
                                                                    ? 'mt5'
                                                                    : 'hidden'
                                                            }
                                                        >
                                                            Estimated Start Date
                                                        </label>
                                                        <p
                                                            className={
                                                                selectedBid.estimated_start_dt
                                                                    ? 'mt5'
                                                                    : 'hidden'
                                                            }
                                                        >
                                                            {moment(
                                                                selectedBid.estimated_start_dt,
                                                            ).format(
                                                                'MM/DD/YYYY',
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="data-detail mt15">
                                                        <label>Member</label>
                                                        <p className="mt5">
                                                            {`${selectedBid.customer.first_name} ${selectedBid.customer.last_name}`}
                                                            <br />
                                                            {
                                                                selectedBid
                                                                    .customer
                                                                    .address
                                                            }
                                                            {selectedBid
                                                                .customer.unit
                                                                ? ` #${selectedBid.customer.unit}`
                                                                : ''}
                                                            ,{' '}
                                                            {
                                                                selectedBid
                                                                    .customer
                                                                    .city
                                                            }{' '}
                                                            {
                                                                selectedBid
                                                                    .customer
                                                                    .state
                                                            }{' '}
                                                            {
                                                                selectedBid
                                                                    .customer
                                                                    .zip_code
                                                            }
                                                            <br />
                                                            <span
                                                                className={
                                                                    user.data
                                                                        .type !==
                                                                    'admin'
                                                                        ? 'hidden'
                                                                        : 'lowercase'
                                                                }
                                                            >
                                                                {
                                                                    selectedBid
                                                                        .customer
                                                                        .email
                                                                }
                                                                <br />
                                                                {formatNumber(
                                                                    selectedBid
                                                                        .customer
                                                                        .phone_number,
                                                                )}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="data-detail mt15">
                                                        <label>Type</label>
                                                        <p className="mt5">
                                                            {selectedBid.type}
                                                        </p>
                                                    </div>
                                                    <div className="data-detail mt15">
                                                        <label>Title</label>
                                                        <p className="mt5">
                                                            {selectedBid.title}
                                                        </p>
                                                    </div>
                                                    <div className="data-detail mt15">
                                                        <label>
                                                            Description
                                                        </label>
                                                        <p className="regularcase mt5">
                                                            {
                                                                selectedBid.description
                                                            }
                                                        </p>
                                                        <div
                                                            className={
                                                                selectedBid.custom_irrigation
                                                                    ? null
                                                                    : 'hidden'
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    'var(--purple0)',
                                                                color: 'white',
                                                                padding:
                                                                    'var(--unit3) var(--unit3)',
                                                                borderRadius:
                                                                    '10px',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            <p className="regularcase">
                                                                Important! The
                                                                customer has
                                                                requested to use
                                                                their own
                                                                irrigation, do
                                                                not include in
                                                                quote.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </Grid>
                                </Grid>
                            </div>
                            {selectedBid.estimated_start_dt && (
                                <div className="mt15 mb15">
                                    <div>
                                        <div>
                                            {selectedBid.line_items
                                                .materials && (
                                                <div>
                                                    <h6 className="mt15">
                                                        Materials $
                                                        {delimit(
                                                            materialsTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h6>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll overflow-y-scroll table-container"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Item
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Price
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {selectedBid.line_items.materials.map(
                                                                        (
                                                                            item,
                                                                            index,
                                                                        ) => (
                                                                            <TableRow
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <TableCell>
                                                                                    <a
                                                                                        href={
                                                                                            item.url
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </a>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    $
                                                                                    {delimit(
                                                                                        item.price.toFixed(
                                                                                            2,
                                                                                        ),
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {
                                                                                        item.qty
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    $
                                                                                    {delimit(
                                                                                        (
                                                                                            item.qty *
                                                                                            item.price
                                                                                        ).toFixed(
                                                                                            2,
                                                                                        ),
                                                                                    )}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ),
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            <h6 className="mt15">
                                                Labor $
                                                {delimit(laborTotal.toFixed(2))}
                                            </h6>
                                            <Grid
                                                container
                                                spacing={2}
                                                className="overflow-x-scroll"
                                            >
                                                <Grid
                                                    item
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Table stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className="table-cell">
                                                                    Item
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    Price
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    Qty
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    Subtotal
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <label>
                                                                        Hourly
                                                                        Rate
                                                                    </label>
                                                                </TableCell>
                                                                <TableCell>
                                                                    $
                                                                    {delimit(
                                                                        selectedBid.line_items.labor.price.toFixed(
                                                                            2,
                                                                        ),
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        selectedBid
                                                                            .line_items
                                                                            .labor
                                                                            .qty
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    $
                                                                    {delimit(
                                                                        (
                                                                            selectedBid
                                                                                .line_items
                                                                                .labor
                                                                                .price *
                                                                            selectedBid
                                                                                .line_items
                                                                                .labor
                                                                                .qty
                                                                        ).toFixed(
                                                                            2,
                                                                        ),
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                            {selectedBid.line_items
                                                .delivery && (
                                                <div>
                                                    <h6 className="mt15">
                                                        Delivery $
                                                        {delimit(
                                                            deliveryTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h6>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Item
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Price
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <label>
                                                                                {
                                                                                    selectedBid
                                                                                        .line_items
                                                                                        .delivery
                                                                                        .name
                                                                                }
                                                                            </label>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            $
                                                                            {delimit(
                                                                                selectedBid.line_items.delivery.price.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            $
                                                                            {delimit(
                                                                                selectedBid.line_items.delivery.price.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            {selectedBid.line_items.rentals && (
                                                <div>
                                                    <h6 className="mt15">
                                                        Rentals $
                                                        {delimit(
                                                            rentalTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h6>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Item
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Price
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <label>
                                                                                {
                                                                                    selectedBid
                                                                                        .line_items
                                                                                        .rentals
                                                                                        .name
                                                                                }
                                                                            </label>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            $
                                                                            {delimit(
                                                                                selectedBid.line_items.rentals.price.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            $
                                                                            {delimit(
                                                                                selectedBid.line_items.rentals.price.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            {selectedBid.line_items
                                                .disposal && (
                                                <div>
                                                    <h6 className="mt15">
                                                        Disposal $
                                                        {delimit(
                                                            disposalTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h6>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Item
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Price
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <label>
                                                                                {
                                                                                    selectedBid
                                                                                        .line_items
                                                                                        .disposal
                                                                                        .name
                                                                                }
                                                                            </label>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            $
                                                                            {delimit(
                                                                                selectedBid.line_items.disposal.price.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            $
                                                                            {delimit(
                                                                                selectedBid.line_items.disposal.price.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb15">
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <div className="p15">
                                                        <h5>Quote Summary</h5>
                                                        <div className="flex flex-space-between mt15">
                                                            <p className="m0">
                                                                Materials
                                                            </p>
                                                            <p className="m0">
                                                                $
                                                                {delimit(
                                                                    materialsTotal.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-space-between">
                                                            <p className="m0">
                                                                Labor
                                                                {deliveryTotal >
                                                                0
                                                                    ? ', Delivery'
                                                                    : ''}
                                                                {rentalTotal > 0
                                                                    ? ', Rentals'
                                                                    : ''}
                                                                {disposalTotal >
                                                                0
                                                                    ? ', Disposal'
                                                                    : ''}
                                                            </p>
                                                            <p className="m0">
                                                                $
                                                                {delimit(
                                                                    (
                                                                        laborTotal +
                                                                        deliveryTotal +
                                                                        rentalTotal +
                                                                        disposalTotal
                                                                    ).toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-space-between">
                                                            <p className="m0">
                                                                Processing Fee
                                                            </p>
                                                            <p className="m0">
                                                                $
                                                                {delimit(
                                                                    paymentProcessingFee.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-space-between">
                                                            <p className="m0">
                                                                Taxes
                                                            </p>
                                                            <p className="m0">
                                                                $
                                                                {delimit(
                                                                    salesTax.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="mt10 mb10">
                                                            <Divider />
                                                        </div>
                                                        <div className="flex flex-space-between">
                                                            <p className="m0">
                                                                Quote Total
                                                            </p>
                                                            <p className="m0">
                                                                $
                                                                {delimit(
                                                                    totalSale.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                    className="payments-separator"
                                                >
                                                    <div className="p15">
                                                        <h5>
                                                            Payout Information
                                                        </h5>
                                                        <div className="flex flex-space-between mt15">
                                                            <p className="m0">
                                                                Labor
                                                            </p>
                                                            <p className="m0">
                                                                $
                                                                {delimit(
                                                                    laborTotal.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                selectedBid.type ===
                                                                'misc'
                                                                    ? 'flex flex-space-between'
                                                                    : 'hidden'
                                                            }
                                                        >
                                                            <p className="m0">
                                                                Yarden Fee (10%)
                                                            </p>
                                                            <p className="m0">
                                                                - $
                                                                {delimit(
                                                                    (
                                                                        laborTotal *
                                                                        MISC_ORDER_PLATFORM_FEE
                                                                    ).toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="mt10 mb10">
                                                            <Divider />
                                                        </div>
                                                        <div className="flex flex-space-between flex-center-y">
                                                            <p className="m0">
                                                                Profit
                                                            </p>
                                                            <p className="m0">
                                                                $
                                                                {selectedBid.type ===
                                                                'misc'
                                                                    ? delimit(
                                                                          (
                                                                              laborTotal -
                                                                              laborTotal *
                                                                                  MISC_ORDER_PLATFORM_FEE
                                                                          ).toFixed(
                                                                              2,
                                                                          ),
                                                                      )
                                                                    : delimit(
                                                                          laborTotal.toFixed(
                                                                              2,
                                                                          ),
                                                                      )}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="text-small text-gray3">
                                                                Payout
                                                                Information is
                                                                only visible to
                                                                you, not the
                                                                customer
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectedBid.attachments &&
                                selectedBid.attachments.length > 0 && (
                                    <div className="mt15">
                                        <div>
                                            <div>
                                                <h5 className="mt0">Images</h5>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mt10 mb25">
                                                <Divider />
                                            </div>
                                            <Grid container spacing={2}>
                                                {selectedBid.attachments.map(
                                                    (b, index) => (
                                                        <Grid
                                                            item
                                                            md={3}
                                                            sm={3}
                                                            xs={12}
                                                            key={index++}
                                                            className="pb15"
                                                        >
                                                            <div className="relative">
                                                                <label className="order-date">
                                                                    {moment(
                                                                        selectedOrder.date,
                                                                    ).format(
                                                                        'MM/DD/YY',
                                                                    )}
                                                                </label>
                                                                <img
                                                                    src={b.url}
                                                                    alt="order images"
                                                                    style={{
                                                                        width: '100%',
                                                                        aspectRatio:
                                                                            '4/3',
                                                                        objectFit:
                                                                            'contain',
                                                                    }}
                                                                    onClick={() =>
                                                                        viewImageDetails(
                                                                            b,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </Grid>
                                                    ),
                                                )}
                                            </Grid>
                                        </div>
                                    </div>
                                )}
                            <div className="mt15">
                                <Divider />
                            </div>
                            <div
                                className={
                                    user.data.type === ADMIN &&
                                    selectedBid &&
                                    selectedBid.status !== 'approved'
                                        ? 'mt25'
                                        : 'hidden'
                                }
                            >
                                <button
                                    className="btn3 small text-red"
                                    onClick={() => cancelBid()}
                                >
                                    <Icon>clear</Icon>
                                    Cancel Quote
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    };

    const renderEditTemplate = () => {
        const { selectedTemplate } = state;

        return (
            <div className="tab-content">
                <LineItemsContextProvider>
                    <TemplateDetails selectedTemplate={selectedTemplate} />
                </LineItemsContextProvider>
            </div>
        );
    };

    const renderEditBid = () => {
        const { selectedBid } = state;

        if (selectedBid) {
            return (
                <div className="tab-content">
                    <LineItemsContextProvider>
                        <BidDetails
                            selectedBid={selectedBid}
                            onClose={() => handleContentClick('Quotes')}
                        />
                    </LineItemsContextProvider>
                </div>
            );
        }
    };

    const renderUserDetails = () => {
        const { selectedUser, allImages, currentImagePage } = state;

        if (selectedUser) {
            return (
                <div className="tab-content">
                    <div className="flex flex-space-between">
                        <div />
                        <button
                            className="btn3 small"
                            onClick={() =>
                                handleContentClick('Edit User', selectedUser)
                            }
                        >
                            <EditOutlined />
                            <span style={{ marginLeft: 8 }}>Edit User</span>
                        </button>
                    </div>
                    {(selectedUser.type === GARDENER ||
                        selectedUser.type === PARTNER) && (
                        <div className="mb20">
                            <label>Status</label>
                            <div>
                                <select
                                    value={selectedUser.active_vendor}
                                    onChange={(e) =>
                                        updateActiveVendorStatus(e.target.value)
                                    }
                                >
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                </select>
                            </div>
                        </div>
                    )}
                    <div className="mb20">
                        <label>User</label>
                        <p className="m0 capitalize">
                            {selectedUser.first_name} {selectedUser.last_name}
                        </p>
                        <p className="m0 capitalize">
                            {selectedUser.address}
                            {selectedUser.unit
                                ? ` #${selectedUser.unit}`
                                : ''}, {selectedUser.city} {selectedUser.state}{' '}
                            {selectedUser.zip_code}
                        </p>
                        <p className="m0">{selectedUser.email}</p>
                        <p className="m0 capitalize">
                            {formatNumber(selectedUser.phone_number)}
                        </p>
                    </div>
                    <div className="capitalize">
                        <label>Type</label>
                        <p>{selectedUser.type}</p>
                    </div>
                    <div className="data-detail">
                        <label>Date Created</label>
                        <p>
                            <Moment format="MM/DD/YYYY">
                                {selectedUser.dt_created}
                            </Moment>
                        </p>
                    </div>
                    <div className="data-detail">
                        <label>Last Login</label>
                        <p>
                            <Moment format="MM/DD/YYYY">
                                {selectedUser.last_login}
                            </Moment>
                        </p>
                    </div>
                    <div className="mt15">
                        <Divider />
                    </div>
                    <div
                        className={
                            allImages.length < 1 ? 'hidden' : 'mt25 mb15'
                        }
                    >
                        <h5>Images</h5>
                        <Grid container spacing={2}>
                            {paginate(allImages, 8, currentImagePage).map(
                                (image, index) => (
                                    <Grid
                                        item
                                        md={3}
                                        sm={3}
                                        xs={12}
                                        key={index}
                                        className="pb15"
                                    >
                                        <div className="relative">
                                            <label className="order-date">
                                                {moment(image.date).format(
                                                    'MM/DD/YY',
                                                )}
                                            </label>
                                            <img
                                                src={image.url}
                                                style={{
                                                    width: '100%',
                                                    aspectRatio: '4/3',
                                                    objectFit: 'contain',
                                                }}
                                                alt="order result"
                                                onClick={() =>
                                                    viewImageDetails(image)
                                                }
                                            />
                                        </div>
                                    </Grid>
                                ),
                            )}
                            <Grid item md={12} sm={12} xs={12} className="mt15">
                                <Pagination
                                    currentPage={state.currentImagePage}
                                    pageLimit={8}
                                    totalCount={allImages.length}
                                    onChange={(page) =>
                                        updateCurrentImagePage(page)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="pt15 pb25 pointer">
                        <span
                            style={{
                                color: 'var(--greenD-50)',
                                float: 'right',
                            }}
                            onClick={() => deleteAccount()}
                        >
                            Delete User
                        </span>
                    </div>
                </div>
            );
        }
    };

    const renderEditUser = () => {
        const {
            selectedUser,
            userFirstName,
            userLastName,
            userEmail,
            userPhoneNumber,
        } = state;
        if (selectedUser) {
            return (
                <div className="tab-content">
                    <div>
                        <label>First Name*</label>
                        <input
                            type="text"
                            name="userFirstName"
                            placeholder="John"
                            value={
                                userFirstName === undefined
                                    ? selectedUser.first_name
                                    : userFirstName
                            }
                            onChange={(e) => setValue(e)}
                        />
                    </div>
                    <div>
                        <label>Last Name*</label>
                        <input
                            type="text"
                            name="userLastName"
                            placeholder="Smith"
                            value={
                                userLastName === undefined
                                    ? selectedUser.last_name
                                    : userLastName
                            }
                            onChange={(e) => setValue(e)}
                        />
                    </div>
                    <div>
                        <label>Email*</label>
                        <input
                            type="email"
                            name="userEmail"
                            placeholder="john@yardengarden.com"
                            value={
                                userEmail === undefined
                                    ? selectedUser.email
                                    : userEmail
                            }
                            onChange={(e) => setValue(e)}
                        />
                    </div>
                    <div>
                        <label style={{ margin: '10px 0px 8px 0px' }}>
                            Phone Number*
                        </label>
                        <input
                            type="text"
                            name="userPhoneNumber"
                            placeholder="(123) 456-7890"
                            value={
                                userPhoneNumber === undefined
                                    ? formatNumber(
                                          selectedUser.phone_number || '',
                                      )
                                    : formatNumber(userPhoneNumber || '')
                            }
                            onChange={(e) => setValue(e)}
                        />
                    </div>
                    <div className="mt15 flex flex-space-between">
                        <div />
                        <div className="flex">
                            <button
                                className="btn3 small mr15 flex flex-center-y"
                                onClick={async () =>
                                    handleContentClick(
                                        'User Details',
                                        selectedUser,
                                    )
                                }
                            >
                                <Icon>close</Icon>{' '}
                                <span style={{ marginLeft: 8 }}>Close</span>
                            </button>
                            <button
                                className="purple small flex flex-center-y"
                                onClick={() => updateUserSettings()}
                            >
                                <Icon>done</Icon>{' '}
                                <span style={{ marginLeft: 8 }}>Finish</span>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderApplicationDetails = () => {
        const { selectedApplication } = state;

        if (selectedApplication) {
            return (
                <div className="tab-content">
                    <ApplicationDetails
                        selectedApplication={selectedApplication}
                        onSetApplicationStatus={(status) => {
                            const updatedApplication = {
                                ...selectedApplication,
                                ...{ status },
                            };
                            updateState({
                                selectedApplication: updatedApplication,
                            });
                        }}
                    />
                </div>
            );
        }
    };

    const renderQRCodeDetails = () => {
        const { selectedQRCode } = state;
        if (selectedQRCode) {
            return (
                <div className="tab-content">
                    <QRCodeDetails
                        changeView={(view) => handleContentClick(view)}
                        selectedQRCode={selectedQRCode}
                    />
                </div>
            );
        }
    };

    const renderTaskDetails = () => {
        const { selectedTask } = state;
        if (selectedTask) {
            return (
                <div className="tab-content">
                    <TaskDetails
                        selectedTask={selectedTask}
                        onSetStatus={() =>
                            handleHeaderContentClick('Reminders')
                        }
                    />
                </div>
            );
        }
    };

    const renderContent = () => {
        if (isSettingsActive) {
            return settingsContent.content;
        } else if (headerContent) {
            const page = headerNavContent.find(
                (item) => item.title === headerContent,
            );
            return page ? page.content : 'Page Not Found';
        } else {
            const page = dashboardContent.find(
                (item) => item.title === content,
            );
            return page ? page.content : 'Page Not Found';
        }
    };

    let dashboardContent = [
        {
            title: 'Orders',
            icon: <EventAvailableOutlinedIcon />,
            content: renderOrders(),
            actions: <div>actions</div>,
        },
        {
            title: 'Quotes',
            icon: <FormatQuoteOutlinedIcon />,
            content: renderQuotes(),
            actions: <div>actions</div>,
        },
        {
            title: 'Order Details',
            content: renderOrderDetails(),
            hidden: true,
        },
        {
            title: 'User Details',
            content: renderUserDetails(),
            hidden: true,
        },
        {
            title: 'Quote Details',
            content: renderBidDetails(),
            hidden: true,
        },
        {
            title: 'Edit Template',
            content: renderEditTemplate(),
            hidden: true,
        },
        {
            title: 'Create Template',
            content: renderEditTemplate(),
            hidden: true,
        },
        {
            title: 'Edit Quote',
            content: renderEditBid(),
            hidden: true,
        },
        {
            title: 'Application Details',
            content: renderApplicationDetails(),
            hidden: true,
        },
        {
            title: 'QR Code Details',
            content: renderQRCodeDetails(),
            hidden: true,
        },
        {
            title: 'Reminder Details',
            content: renderTaskDetails(),
            hidden: true,
        },
        {
            title: 'Create Order',
            content: renderCreateOrder(),
            hidden: true,
        },
        {
            title: 'Create Quote',
            content: renderCreateBid(),
            hidden: true,
        },
        {
            title: 'Edit User',
            content: renderEditUser(),
            hidden: true,
        },
    ];

    if (props.user.data.type === ADMIN) {
        dashboardContent.push(
            {
                title: 'Users',
                icon: <GroupOutlinedIcon />,
                content: renderUsers(),
            },
            {
                title: 'Materials',
                icon: <BuildOutlinedIcon />,
                content: renderMaterials(),
                actions: <div>actions</div>,
            },
            {
                title: 'Templates',
                icon: <DescriptionOutlinedIcon />,
                content: renderTemplates(),
                actions: <div>actions</div>,
            },
            {
                title: 'Automation',
                icon: <OfflineBoltOutlinedIcon />,
                content: renderAutomation(),
            },
            {
                title: 'QR Codes',
                icon: <CropFreeOutlinedIcon />,
                content: renderQRCodes(),
            },
            {
                title: 'Applications',
                icon: <WorkOutlineOutlinedIcon />,
                content: renderJobApplications(),
            },
        );
    }

    const settingsContent = {
        title: 'Settings',
        icon: <SettingsOutlinedIcon />,
        content: renderEditUser(),
    };

    const headerNavContent = [
        {
            title: 'Reminders',
            icon: <NotificationsOutlinedIcon />,
            content: renderTasks(),
            value: 'reminders',
        },
        {
            title: 'Messages',
            icon: <MailOutlineOutlinedIcon />,
            content: renderMessages(),
            value: 'messages',
        },
        {
            title: 'Logout',
            icon: <ExitToAppOutlinedIcon />,
            content: () => <></>,
            value: 'logout',
        },
    ];

    const renderActions = () => {
        let action = () => {};
        let inputName = 'search';
        let renderNewInstanceButton = props.user.data.type === ADMIN;
        let renderSearchInput = true;
        let shouldRender = true;

        if (state.viewOrders) {
            action = () => handleContentClick('Create Order');
            inputName = 'orderSearch';
        } else if (state.viewBids) {
            action = () => handleContentClick('Create Quote');
            inputName = 'bidSearch';
        } else if (state.viewMaterials) {
            if (!state.newItem) {
                action = () => {
                    updateState({
                        newItem: true,
                        itemBuilderTitle: 'Create',
                        itemName: '',
                        itemUrl: '',
                        itemPrice: '',
                    });
                };
            } else {
                shouldRender = false;
            }

            inputName = 'itemSearch';
        } else if (state.viewTemplates) {
            action = () => handleContentClick('Create Template');
            inputName = 'templateSearch';
        } else if (state.viewUsers) {
            action = () => handleContentClick('Create Order');
            inputName = 'userSearch';
        } else if (state.viewAutomation) {
            if (!state.newRule) {
                action = () => {
                    updateState({
                        newRule: true,
                        ruleBuilderTitle: 'Create',
                        itemName: '',
                        itemQty: '',
                        unitQty: '',
                        unitType: 'bid',
                    });
                };
            } else {
                shouldRender = false;
            }
            inputName = 'ruleSearch';
        } else if (state.viewApplications) {
            inputName = 'applicationSearch';
            renderNewInstanceButton = false;
        } else if (state.viewQRCodes) {
            action = () => {
                updateState({ newQRModalIsOpen: true });
            };

            inputName = 'qrCodeSearch';
        } else if (state.viewTasks) {
            action = () => {
                updateState({
                    taskModalIsOpen: true,
                });
            };
            inputName = 'taskSearch';
            renderNewInstanceButton = true;
        } else if (state.viewMessages) {
            shouldRender = false;
        } else if (state.viewSettings) {
            shouldRender = false;
        } else {
            shouldRender = false;
        }

        if (shouldRender) {
            return (
                <>
                    {' '}
                    {renderNewInstanceButton && (
                        <button
                            style={{
                                marginLeft: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                padding: 'var(--unit4)',
                            }}
                            onClick={() => action()}
                        >
                            <AddIcon />
                        </button>
                    )}
                    {renderSearchInput && (
                        <div className="orders-search">
                            <input
                                type="text"
                                variant="outlined"
                                placeholder="🔍 Search"
                                name={inputName}
                                value={state[`${inputName}`] || ''}
                                onChange={(e) =>
                                    updateState({
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                        </div>
                    )}
                </>
            );
        } else {
            return <></>;
        }
    };

    const [content, setContent] = useState(dashboardContent[0].title);
    const [activeTitle, setActiveTitle] = useState(dashboardContent[0].title);
    const [navOpen, setNavOpen] = useState(false);
    const [headerContent, setHeaderContent] = useState(null);
    const [isSettingsActive, setIsSettingsActive] = useState(false);

    const {
        selectedImage,
        imageModalIsOpen,
        newQRModalIsOpen,
        loadingModalIsOpen,
        taskModalIsOpen,
    } = state;

    return (
        <span>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            <ImageModal
                image={selectedImage}
                isOpen={imageModalIsOpen}
                onClose={() => updateState({ imageModalIsOpen: false })}
            />

            <NewQRModal
                isOpen={newQRModalIsOpen}
                onClose={() => updateState({ newQRModalIsOpen: false })}
            />

            <TaskModal
                isOpen={taskModalIsOpen}
                onClose={() => updateState({ taskModalIsOpen: false })}
            />

            {/* dashboard */}
            <div className="dashboard2-container">
                <header className="dashboard-header">
                    <Logo
                        style={{ margin: 'var(--unit3) var(--unit4)' }}
                        height={36}
                    />
                    <Tabs
                        className="header-nav"
                        onChange={(item) => {
                            if (item.title === 'Logout') {
                                props.logout();
                            } else {
                                handleHeaderContentClick(item.title);
                            }
                        }}
                        data={headerNavContent}
                    />
                </header>
                <div className="dashboard2">
                    <main>
                        <div
                            className="content-wrapper"
                            style={{
                                height: state.showMap
                                    ? 'calc(115vh + 400px)'
                                    : '115vh',
                            }}
                        >
                            <div className="content-header-wrapper">
                                <h3 className="content-header">
                                    {activeTitle}
                                </h3>
                                {renderActions()}
                            </div>
                            <div className="content">{renderContent()}</div>
                        </div>
                    </main>
                    <aside className={`sidenav ${navOpen ? 'closed' : 'open'}`}>
                        <div className="sidebar-content">
                            <div className="sidebar-header">
                                <h5 className="avatar text-center capitalize">
                                    {props.user.data.first_name[0]}
                                    {props.user.data.last_name[0]}
                                </h5>
                                <h5 className="user-name">
                                    {props.user.data.first_name}
                                </h5>
                            </div>
                            <div className="sidebar-links">
                                <ul>
                                    {dashboardContent.map((item, index) => (
                                        <li
                                            className={`nav-link ${
                                                item.title === content
                                                    ? 'active flex flex-center-y'
                                                    : 'flex flex-center-y'
                                            } ${item.hidden ? 'hidden' : ''}`}
                                            key={index}
                                            onClick={() =>
                                                handleContentClick(item.title)
                                            }
                                        >
                                            {item.icon}
                                            <span className="link-title">
                                                {item.title}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <li
                                    className={`nav-link ${
                                        isSettingsActive
                                            ? 'active flex flex-center-y'
                                            : 'flex flex-center-y'
                                    }`}
                                    onClick={() =>
                                        handleContentClick(
                                            'User Details',
                                            props.user.data,
                                        )
                                    }
                                >
                                    <SettingsOutlinedIcon />
                                    <span className="link-title">Settings</span>
                                </li>
                            </div>
                        </div>
                        <button
                            className="nav-toggle btn3"
                            onClick={handleNavOpen}
                        >
                            <span style={{ position: 'absolute' }}>
                                {navOpen ? '>' : '<'}
                            </span>
                        </button>
                    </aside>
                    {activeTitle === 'Order Details' &&
                        props.plantSelection &&
                        props.plantSelection.data && (
                            <Plants
                                plantSelection={props.plantSelection.data}
                            />
                        )}
                </div>
            </div>
        </span>
    );
};

function mapStateToProps(state) {
    return {
        users: state.users,
        orders: state.order,
        bids: state.bid,
        applications: state.application,
        error: state.error,
        status: state.status,
        bidStatus: state.bidStatus,
        range: state.range,
        type: state.type,
        user: state.user,
        templates: state.template,
        rules: state.rule,
        items: state.item,
        tasks: state.task,
        taskStatus: state.taskStatus,
        changeOrders: state.changeOrder,
        navigation: state.navigation,
        render: state.render,
        answers: state.answer,
        referrals: state.referral,
        products: state.product,
        shapes: state.shape,
        qrCodes: state.qrCode,
        messages: state.message,
        plantSelection: state.plantSelection,
    };
}

Dashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default Dashboard;
