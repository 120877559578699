// company info
export const PHONE_NUMBER = '8888289287';
export const FULL_PLAN_RATE = 299;
export const STANDARD_LABOR_RATE_PER_HOUR = 35;
export const GARDEN_BOOTCAMP_FEE = 99;

// accepted payment methods
export const VISA = 'visa';
export const MASTERCARD = 'mastercard';
export const AMERICAN_EXPRESS = 'american express';
export const DISCOVER = 'discover';

// fees
export const SALES_TAX = 0.0725; // CA sales tax
export const PAYMENT_PROCESSING_FEE = 0.03; // Stripe
export const MISC_ORDER_PLATFORM_FEE = 0.1; // Platform fee for misc orders completed by vendors

// limits
export const MAX_PLANT_VARIETY_LIMIT_PER_GARDEN = 15;
export const MAX_FAVORITES_LIMIT_PER_SEASON = 5;

// appointment scheduling
export const APPOINTMENT_URL = 'https://tidycal.com/yarden-garden/appointment';
